<template>
  <f7-page name="challenges">
    <f7-navbar title="Challenges">
      <f7-link slot="left" @click="goBack()">
        <f7-icon icon="icon-back"></f7-icon>
      </f7-link>
    </f7-navbar>

    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found"></f7-list-item>
    </f7-list>
    <f7-list
      v-if="challenges"
      class="searchbar-found"
      media-list
      virtual-list
      :virtual-list-params="{ items, searchAll, height: $theme.ios ? 63 : ($theme.md ? 73 : 46)}"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in challenges"
          swipeout
          :key="index"
          media-item
          link="#"
          :title="item.name"
          :subtitle="getWord('words.length') + item.distance + kmText + seperatorText + getWord('words.checkpoints') + item.checkpoints.length"
          @swipeout:open="swipeIsBusy = true"
          @swipeout:opened="swipeIsBusy = false"
          @swipeout:close="swipeIsBusy = true"
          @swipeout:closed="swipeIsBusy = false"
          @click="openChallenge(challengesId[index])"
          v-bind:class="{itemNotActive: !item.active}"
        >
          <f7-swipeout-actions v-if="canCreate" right>
            <f7-swipeout-button color="green" @click="editChallenge(challengesId[index])">Edit</f7-swipeout-button>
          </f7-swipeout-actions>
          <f7-icon
            v-if="item.difficulty == 0"
            class="color-green"
            slot="media"
            ios="material:filter_vintage"
            aurora="material:filter_vintage"
            md="material:filter_vintage"
          ></f7-icon>
          <f7-icon
            v-if="item.difficulty == 1"
            class="color-orange"
            slot="media"
            ios="material:grain"
            aurora="material:grain"
            md="material:grain"
          ></f7-icon>
          <f7-icon
            v-if="item.difficulty == 2"
            class="themeColorEl"
            slot="media"
            ios="material:whatshot"
            aurora="material:whatshot"
            md="material:whatshot"
          ></f7-icon>
        </f7-list-item>
      </ul>
    </f7-list>
  </f7-page>
</template>

<script>
import pcLogo from "../images/primus-challenge-logo-512.png";
import { createMapPointer } from "../js/map";
const fb = require("../js/fb.js");
const map = require("../js/map.js");
const lg = require("../js/lang.js");

export default {
  data() {
    return {
      appLang: lg.appLang,
      items: null,
      user: null,
      user_data: null,
      pcLogo: pcLogo,
      canCreate: null,
      swipeIsBusy: false,
      challenges: null,
      challengesId: null,
      lengthText: "Longeur env.: ",
      kmText: " km",
      seperatorText: " - ",
      checkpointsText: " Checkpoints: ",
      checkpoints: null
    };
  },
  methods: {
    getWord: function(w) {
      return lg.getWord(w);
    },
    goBack() {
      var myRouter = this.$f7.views.main.router;

      myRouter.navigate({
        url: "/",
        force: true,
        ignoreCache: true,
        clearPreviousHistory: true
      });
    },
    searchAll(query, challenges) {
      const found = [];
      for (let i = 0; i < challenges.length; i += 1) {
        if (
          challenges[i].title.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
          query.trim() === ""
        )
          found.push(i);
      }
      return found; // return array with mathced indexes
    },
    editChallenge(id) {
      //console.log("edit challenge: " + id);
      this.$f7.views.main.router.navigate({
        url: "/create/?id=" + id,
        force: true,
        ignoreCache: true
      });
    },
    openChallenge(id) {
      if (!this.swipeIsBusy) {
        //console.log("open challenge: " + id);
        this.$f7.views.main.router.navigate({
          url: "/game/?id=" + id,
          force: true,
          ignoreCache: true,
          pushState: false
        });
      }
    }
  },
  mounted() {
    console.log(this.appLang);

    var f7 = this.$f7;
    f7.dialog.close();
    var me = this;

    // set user
    this.user = fb.auth.currentUser;
    this.user_data = fb.currentUserData;
    this.canCreate = fb.currentUserData.canCreate;

    // get challenges

    this.challenges = [];
    this.challengesId = [];

    fb.challengesCollection.get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        if (me.canCreate) {
          me.challenges.push(doc.data());
          me.challengesId.push(doc.id);
        } else if (doc.data().active) {
          me.challenges.push(doc.data());
          me.challengesId.push(doc.id);
        }
      });
    });
    
  }
};
</script>

<style>
.pin-map {
  height: 100vw;
  max-height: 50vh;
  width: 100%;
}
</style>
// language
import language from "../static/language/lang.json";

var fallBackLang = "en";

var availableLanguages = ["en", "de", "fr"];

var locale = [
    ...(window.navigator.languages || []),
    window.navigator.language,
    window.navigator.browserLanguage,
    window.navigator.userLanguage,
    window.navigator.systemLanguage
  ]
  .filter(Boolean)
  .map(language => language.substr(0, 2))
  .find(language => availableLanguages.includes(language)) || fallBackLang;

console.log(locale);

var appLang = locale;

function updateLang(l) {
  appLang = l;
  return appLang;
}

function getWord(wd) {
  var w = wd;
  w = w.split('.');
  var word = language[appLang][w[0]][w[1]];
  if (typeof word == 'undefined') {
    word = wd;
    if (typeof language[fallBackLang][w[0]][w[1]] != 'undefined') {
      if (fallBackLang != appLang) {
        console.error(w + ' not exists in ' + appLang);
      }
      word = language[fallBackLang][w[0]][w[1]];
    }else{
      console.error(w + ' not exists in any language');
    }
  } 
  return word;
}

export {
  appLang,
  language,
  updateLang,
  getWord
};
// openlayers imports

import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorTileLayer from 'ol/layer/VectorTile';
import Vector from 'ol/layer/Vector';
import {
  XYZ,
  OSM,
  TileDebug
} from 'ol/source';
import VectorSource from 'ol/source/Vector';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import Point from 'ol/geom/Point';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Icon,
} from 'ol/style';
import Text from "ol/style/Text";
import {
  defaults as defaultControls,
  Control
} from 'ol/control';
import Overlay from 'ol/Overlay';
import LineString from 'ol/geom/LineString';
import {
  fromLonLat,
  toLonLat,
  transform
} from 'ol/proj';
import {
  getDistance
} from 'ol/sphere';
import {
  defaults as defaultInteractions
} from 'ol/interaction';

import pcLogo from "../images/primus-challenge-logo-512.png";

const checkSympols = {
  "pin": "place",
  "pin0": "star",
  "pin1": "toys",
  "pin2": "local_dining",
  "pin3": "local_drink",
  "pin998": "alarm",
  "pin999": "stars",
}

//
class createMapPointer {
  constructor(el, parent, startZoom, maxZoom, minZoom, returnCoords, pinFlex) {
    var self = this;
    var startCoords = [6.524637, 46.562143];
    var defaultZoom = startZoom;
    var maxZoom = maxZoom;
    var minZoom = minZoom;
    var returnCoords = returnCoords;
    this.parent = parent;

    this.placeLayer = null;
    this.shadowStyle = null;
    this.pinStyle = null;
    this.pinT = 'pin';

    if (pinFlex) {
      this.pinFlex = true;
    }

    var key = 'pk.eyJ1Ijoid2Vic3RhbjcyIiwiYSI6ImNrNnYxbGYzMDBhcHEzZW9hbjJsdDl0YWEifQ.UF3TpISrPrdwL74G3lUTUA';

    
    this.mapLayer = new TileLayer({
      
      source: new XYZ({
        urls: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png"]
      })
    });
    
    /*

    this.mapLayer = new VectorTileLayer({
      declutter: true,
      source: new VectorTileSource({
        attributions: '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> ' +
          '© <a href="https://www.openstreetmap.org/copyright">' +
          'OpenStreetMap contributors</a>',
        format: new MVT(),
        url: 'https://{a-d}.tiles.mapbox.com/v4/mapbox.mapbox-streets-v6/' +
            '{z}/{x}/{y}.vector.pbf?access_token=' + key
      }),
      style: createMapboxStreetsV6Style(Style, Fill, Stroke, Icon, Text)
    });

    

    //mapbox://styles/webstan72/ck7un77zc0zyr1ikz3q6z18zj
    //https://api.mapbox.com/styles/v1/webstan72/ck7un77zc0zyr1ikz3q6z18zj.html?fresh=true&title=view&access_token=pk.eyJ1Ijoid2Vic3RhbjcyIiwiYSI6ImNrNnYxbGYzMDBhcHEzZW9hbjJsdDl0YWEifQ.UF3TpISrPrdwL74G3lUTUA

    
    this.mapLayer = new TileLayer({

      source: new XYZ({
        url: 'https://api.mapbox.com/v4/mapbox.outdoors/{z}/{x}/{y}.png'+'?access_token='+key,
      })
    });
    */




    this.view = new View({
      center: fromLonLat(startCoords),
      minZoom: minZoom,
      maxZoom: maxZoom,
      zoom: defaultZoom
    });

    var geolocation = new Geolocation({
      projection: this.view.getProjection(),
      trackingOptions: {
        maximumAge: 10000,
        enableHighAccuracy: true,
        timeout: 600000
      }
    });

    // custom control

    var GetGeoPosition = (function (Control) {
      function GetGeoPosition(opt_options) {
        var options = opt_options || {};

        var geoButton = document.createElement('button');
        geoButton.type = 'button';
        geoButton.innerHTML = '<i class="material-icons" style="font-size: 1.14em;">my_location</i>';

        var element = document.createElement('div');
        element.className = 'get-geo ol-unselectable ol-control';
        element.appendChild(geoButton);

        Control.call(this, {
          element: element,
          target: options.target
        });

        geoButton.addEventListener('click', this.handleGetGeo.bind(this), false);
      }

      if (Control) GetGeoPosition.__proto__ = Control;
      GetGeoPosition.prototype = Object.create(Control && Control.prototype);
      GetGeoPosition.prototype.constructor = GetGeoPosition;

      GetGeoPosition.prototype.handleGetGeo = function handleGetGeo() {
        //console.log('get');
        geolocation.setTracking(true);

      };

      return GetGeoPosition;
    }(Control));
    //-----------------------------------------

    geolocation.on('change', function () {
      //console.log('got');
      self.setPinOnMap(geolocation.getPosition());
    });

    this.map = new Map({
      interactions: defaultInteractions({
        mouseWheelZoom: false,
        //onFocusOnly: true,
        pinchZoom: true,
      }),
      controls: defaultControls().extend([
        new GetGeoPosition()
      ]),
      layers: [self.mapLayer],
      target: el,
      view: this.view,
    });

    this.map.on("singleclick", function (evt) {
      self.setPinOnMap(evt.coordinate);
    })
    //
    this.setPinOnMapLatLong = function (pos) {
      //
      this.setPinOnMap(fromLonLat(pos));
    }

    this.setPinOnMap = function (pos) {

      //console.log(checkSympols['pin' + this.parent.chkTypeInput]);
      if (this.pinFlex) {
        this.pinT = 'pin' + this.parent.chkTypeInput;
      }

      geolocation.setTracking(false);

      var latLong = toLonLat(pos);
      //console.log(latLong);

      returnCoords(latLong);

      this.map.removeLayer(this.placeLayer);

      //************************************************************************ */

      var features = [];

      var iconFeature = new Feature({
        geometry: new Point(pos)
      });

      var iconStyle = new Style({
        text: new Text({
          text: checkSympols[this.pinT],
          font: "normal 72px Material Icons",
          offsetY: -31,
          fill: new Fill({
            color: 'rgb(227, 6, 19)'
          }),
        }),
        zIndex: 2
      });

      var shadowStyle = new Style({
        text: new Text({
          text: checkSympols[this.pinT],
          font: "normal 72px Material Icons",
          offsetY: -31,
          rotation: 0.65,
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0.3)'
          }),
        }),
        zIndex: 1
      });

      var pointStyle = new Style({
        text: new Text({
          text: 'adjust',
          font: "normal 24px Material Icons",
          fill: new Fill({
            color: 'rgb(227, 6, 19)'
          }),
        }),
        zIndex: 0
      });

      iconFeature.setStyle([iconStyle, shadowStyle, pointStyle]);
      features.push(iconFeature);

      var vectorSource = new VectorSource({
        features: features
      });

      this.placeLayer = new Vector({
        source: vectorSource
      });

      this.map.addLayer(this.placeLayer);

      setTimeout(function () {
        self.view.animate({
          center: pos,
          duration: 300
        });
      }, 100);


    }

    this.deletePinOnMap = function () {
      this.map.removeLayer(this.placeLayer);
    }

    this.chkPointsLayers = [];

    // setAllCheckpoints(me.checkPointsList)
    this.setAllCheckpoints = function (chkPoints) {

      //console.log('set all chk');
      //console.log(chkPoints);
      //console.log(this.parent.selectedCheckpoint);
      var selChk = null;
      if (this.parent.selectedCheckpoint) {
        selChk = this.parent.selectedCheckpoint;
      }

      // remove old layers
      for (var i = 0; i < this.chkPointsLayers.length; i++) {
        var currentChkLayer = this.chkPointsLayers[i];
        this.map.removeLayer(currentChkLayer);
      }

      this.chkPointsLayers = [];

      var chkNumber = chkPoints.length - 1;

      for (var i = -1; i < chkPoints.length; i++) {
        var currentChk;
        var pos = fromLonLat([this.parent.selectedChallenge.long, this.parent.selectedChallenge.lat]);
        var pointText = i;

        if (i == -1) {
          pointText = "S";
          var currentPinT = 'pin998';
        } else if (i >= chkNumber) {
          pointText = "E";
          currentChk = chkPoints[i];
          pos = fromLonLat([currentChk.long, currentChk.lat]);
          var currentPinT = 'pin999';
        } else {
          pointText = i + 1;
          currentChk = chkPoints[i];
          pos = fromLonLat([currentChk.long, currentChk.lat]);
          var currentPinT = 'pin' + currentChk.type;
        }

        var features = [];

        var iconFeature = new Feature({
          geometry: new Point(pos)
        });

        var bgColor = 'rgb(100, 0, 0)';

        if (this.parent.selectedCheckpoint && i >= 0) {
          pos = fromLonLat([this.parent.selectedCheckpoint.long, this.parent.selectedCheckpoint.lat]);

          if (this.parent.selectedCheckpoint.lat === currentChk.lat && this.parent.selectedCheckpoint.long === currentChk.long) {
            bgColor = 'rgb(0, 100, 255)';
          } else {
            bgColor = 'rgb(100, 0, 0)';
          }

        }

        var numStyle = new Style({
          text: new Text({
            text: '' + pointText + '',
            offsetY: 1,
            scale: 1.5,
            fill: new Fill({
              color: 'rgb(255, 255, 255)'
            }),
          }),
          zIndex: 3
        });

        var iconStyle = new Style({
          text: new Text({
            text: checkSympols[currentPinT],
            font: "normal 72px Material Icons",
            offsetY: -31,
            scale: 0.5,
            fill: new Fill({
              color: bgColor
            }),
          }),
          zIndex: 2
        });

        var shadowStyle = new Style({
          text: new Text({
            text: checkSympols[currentPinT],
            font: "normal 72px Material Icons",
            offsetY: -31,
            scale: 0.5,
            rotation: 0.65,
            fill: new Fill({
              color: 'rgba(0, 0, 0, 0.3)'
            }),
          }),
          zIndex: 1
        });

        var pointStyle = new Style({
          text: new Text({
            text: 'lens',
            font: "normal 36px Material Icons",
            fill: new Fill({
              color: bgColor
            }),
          }),
          zIndex: 0
        });

        iconFeature.setStyle([numStyle, iconStyle, shadowStyle, pointStyle]);
        features.push(iconFeature);

        var vectorSource = new VectorSource({
          features: features
        });

        var chkPlaceLayer = new Vector({
          source: vectorSource
        });

        this.chkPointsLayers.push(chkPlaceLayer);

        this.map.addLayer(chkPlaceLayer);

        if (i >= chkNumber) {
          setTimeout(function () {
            self.view.animate({
              center: pos,
              duration: 500,
              zoom: defaultZoom,
            });
          }, 100);
        }

      }


    }


  }


}


export {
  createMapPointer
};
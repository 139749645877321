var cameraStream = null;
var stream = null;
var capture = null;
var streaming = false;

var constraints = {
  audio: false,
  video: {
    width: {
      ideal: 1920
    },
    height: {
      ideal: 1080
    }
  }
};

// Start Streaming
function startStreaming(streamEl, captEl) {

  stream = document.getElementById(streamEl);
  capture = document.getElementById(captEl);

  var mediaSupport = 'mediaDevices' in navigator;

  if (mediaSupport && null == cameraStream) {

    navigator.mediaDevices.getUserMedia(constraints)
      .then(function (mediaStream) {

        cameraStream = mediaStream;

        stream.srcObject = mediaStream;

        var frameR = mediaStream.getVideoTracks()[0].getSettings().frameRate;

        stream.play();
        console.log(mediaStream.getVideoTracks()[0].getSettings().width + ' x ' + mediaStream.getVideoTracks()[0].getSettings().height);
        capture.width = mediaStream.getVideoTracks()[0].getSettings().width;
        capture.height = mediaStream.getVideoTracks()[0].getSettings().height;

        streaming = true;
        var streamLoop;

        var ctx = capture.getContext('2d');
        var $this = this; //cache
        (function loop() {
          if (streaming) {
            console.log('loop');
            ctx.drawImage(stream, 0, 0);
            streamLoop = setTimeout(loop, 1000 / frameR); // drawing at 30fps
          } else {
            streamLoop = clearTimeout();
          }

        })();

      })
      .catch(function (err) {

        console.log("Unable to access camera: " + err);
      });
  } else {

    console.log('Your browser does not support media devices.');

    return;
  }
}

function getPictureFromDevice() {
  //
}

// Stop Streaming
function stopStreaming() {

  if (null != cameraStream) {

    var track = cameraStream.getTracks()[0];

    track.stop();
    stream.load();

    streaming = false;

    cameraStream = null;
  }
}


function captureSnapshot() {

  if (null != cameraStream) {

    var img = new Image();

    img.src = capture.toDataURL("image/png");

    streaming = false;
    stopStreaming();

    return img.src;
  }

}

function captureInputPic() {

  if (null != cameraStream) {


    streaming = false;
    stopStreaming();

  }

}

function dataURItoBlob(dataURI) {

  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  var buffer = new ArrayBuffer(byteString.length);
  var data = new DataView(buffer);

  for (var i = 0; i < byteString.length; i++) {

    data.setUint8(i, byteString.charCodeAt(i));
  }

  return new Blob([buffer], {
    type: mimeString
  });
}

export {
  startStreaming,
  stopStreaming,
  captureSnapshot,
  captureInputPic,
  getPictureFromDevice,
  dataURItoBlob
};
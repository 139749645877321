<template>
  <f7-page name="create">
    <f7-navbar :title="pageTitle">
      <f7-link slot="left" @click="goBack()">
        <f7-icon icon="icon-back"></f7-icon>
      </f7-link>
    </f7-navbar>
    <f7-block>
      <f7-button
        v-if="!showChallengeSet && !showCheckpointInput"
        fill
        v-on:click="onShowChallengeSet"
      >Show Challenge settings</f7-button>
      <f7-button
        v-if="showChallengeSet && selectedChallenge"
        fill
        v-on:click="showChallengeSet = false"
      >Hide Challenge settings</f7-button>
    </f7-block>
    <!-- Challenge ***************************************************************************************** -->
    <form @submit.prevent="onChallengCreate" action method="GET">
      <f7-list v-show="showChallengeSet">
        <f7-list-item divider title="General infos"></f7-list-item>
        <f7-list-input
          :value="challengeNameInput"
          @input="challengeNameInput = $event.target.value"
          label="Challenge Name"
          type="text"
          placeholder="Challenge Name"
          required
          class="challengeName"
        >
          <f7-icon
            slot="media"
            ios="material:explore"
            aurora="material:explore"
            md="material:explore"
          ></f7-icon>
        </f7-list-input>

        <f7-list-input
          :value="challengeDescrInput"
          @input="challengeDescrInput = $event.target.value"
          label="Challenge Description"
          type="textarea"
          placeholder="Challenge Description"
          class="challengeDescription"
        >
          <f7-icon
            slot="media"
            ios="material:short_text"
            aurora="material:short_text"
            md="material:short_text"
          ></f7-icon>
        </f7-list-input>

        <f7-list-input
          class="difficulty"
          :value="difficultyInput"
          @input="difficultyInput = $event.target.value"
          label="Difficulty"
          type="select"
          required
        >
          <f7-icon
            slot="media"
            ios="material:whatshot"
            aurora="material:whatshot"
            md="material:whatshot"
          />
          <option value="0">Easy</option>
          <option value="1">Medium</option>
          <option value="2">Hard</option>
        </f7-list-input>

        <f7-list-input
          :value="distanceInput"
          @input="distanceInput = $event.target.value"
          label="Distance"
          type="number"
          placeholder="Distance in km"
          info="in km"
          class="distance"
        >
          <f7-icon
            slot="media"
            ios="material:directions_bike"
            aurora="material:directions_bike"
            md="material:directions_bike"
          ></f7-icon>
        </f7-list-input>

        <f7-list-item
          id="activateChallenge"
          checkbox
          name="challenge-active"
          :value="activateInput"
          title="Challenge active"
        ></f7-list-item>

        <f7-list-item divider title="Start Coordinates"></f7-list-item>

        <f7-list-item accordion-item accordion-item-opened title="Map">
          <f7-icon
            slot="media"
            ios="material:explore"
            aurora="material:explore"
            md="material:explore"
          ></f7-icon>
          <f7-accordion-content>
            <f7-block>
              <div id="map" class="pin-map"></div>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-input
          :value="latitudeInput"
          @input="latitudeInput = $event.target.value"
          class="latitude"
          label="Latitude"
          type="number"
          step="0.000000000000001"
          placeholder="Lat"
          required
        >
          <f7-icon slot="media" ios="f7:globe" aurora="f7:globe" md="f7:globe"></f7-icon>
        </f7-list-input>
        <f7-list-input
          :value="longitudeInput"
          @input="longitudeInput = $event.target.value"
          class="longitude"
          label="Longitude"
          type="number"
          step="0.000000000000001"
          placeholder="Long"
          required
        >
          <f7-icon slot="media" ios="f7:globe" aurora="f7:globe" md="f7:globe"></f7-icon>
        </f7-list-input>

        <f7-list-item divider title="Bike type"></f7-list-item>

        <f7-list-item id="velo0" checkbox name="bike-type" :value="veloInput0" title="MTB" checked></f7-list-item>
        <f7-list-item id="velo1" checkbox name="bike-type" :value="veloInput1" title="City" checked></f7-list-item>
        <f7-list-item
          id="velo2"
          checkbox
          name="bike-type"
          :value="veloInput2"
          title="Racing"
          checked
        ></f7-list-item>
      </f7-list>
      <f7-block>
        <f7-button v-if="!selectedChallenge" fill type="submit">Create Challenge</f7-button>
      </f7-block>
      <f7-block>
        <f7-button v-if="showChallengeSet && selectedChallenge" fill type="submit">Update Challenge</f7-button>
      </f7-block>
    </form>
    <f7-list v-if="!showChallengeSet">
      <f7-list-item divider :title="challengeName + seperatorText + checkpointsText"></f7-list-item>
    </f7-list>
    <!-- Checkpoints ***************************************************************************************** -->
    <f7-list
      v-show="!showChallengeSet && !showCheckpointInput"
      @sortable:sort="onCheckSort"
      sortable
      media-list
      virtual-list
      :virtual-list-params="{ items, searchAll, height: $theme.ios ? 63 : ($theme.md ? 73 : 46)}"
      class="sortable-enabled"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in checkPointsList"
          :key="index"
          media-item
          link="#"
          swipeout
          :title="checkpointText + (index+1)"
          :virtual-list-index="index"
          @swipeout:open="swipeIsBusy = true"
          @swipeout:opened="swipeIsBusy = false"
          @swipeout:close="swipeIsBusy = true"
          @swipeout:closed="swipeIsBusy = false"
          @click="openExistingCheckpoint(index)"
        >
          <f7-swipeout-actions v-if="canCreate" right>
            <f7-swipeout-button color="red" @click="deleteCheckpoint(index)">Delete</f7-swipeout-button>
          </f7-swipeout-actions>
          <f7-icon
            slot="media"
            :ios="checkPointSymbols[checkPointsList[index].type]"
            :aurora="checkPointSymbols[checkPointsList[index].type]"
            :md="checkPointSymbols[checkPointsList[index].type]"
          ></f7-icon>
        </f7-list-item>
        <f7-list-item
          link="#"
          v-show="!showCheckpointInput"
          v-on:click="onOpenCheckpointEdit"
          title="Add a Checkpoint"
        >
          <f7-icon
            slot="media"
            ios="material:add_location"
            aurora="material:add_location"
            md="material:add_location"
          ></f7-icon>
        </f7-list-item>
      </ul>
    </f7-list>
    <!-- Checkpoints Details ***************************************************************************************** -->
    <form @submit.prevent="onCheckpointCreate" action method="GET">
      <f7-block v-show="showCheckpointInput">
        <f7-row>
          <f7-col width="75">
            <f7-button fill type="submit">Save Checkpoint</f7-button>
          </f7-col>
          <f7-col width="25">
            <f7-button v-on:click="onCancelCheck" class="button-outline">Cancel</f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
      <!-- Checkpoints Inputs ***************************************************************************************** -->
      <f7-list v-show="showCheckpointInput">
        <f7-list media-list>
          <f7-list-item
            link="#"
            :title="getWord('titles.checkpointphoto')"
            :text="getWord('sentences.checkpointphoto')"
            @click="addCheckpointPhoto()"
          >
            <img v-if="checkpointPhoto" slot="media" :src="checkpointPhoto" class="chkPhoto" />
            <f7-icon
              v-else
              slot="media"
              ios="material:add_a_photo"
              aurora="material:add_a_photo"
              md="material:add_a_photo"
            ></f7-icon>
          </f7-list-item>
        </f7-list>
        <f7-list-input
          :value="checkpointTextInput"
          @input="checkpointTextInput = $event.target.value"
          label="Checkpoint Text"
          type="textarea"
          placeholder="Checkpoint Text"
          required
          class="checkpointText"
        >
          <f7-icon
            slot="media"
            ios="material:short_text"
            aurora="material:short_text"
            md="material:short_text"
          ></f7-icon>
        </f7-list-input>
        <f7-list-input
          class="chkType"
          :value="chkTypeInput"
          @input="chkTypeInput = $event.target.value"
          label="Checkpoint Type"
          type="select"
          v-on:change="onChangeChkType"
          required
        >
          <f7-icon slot="media" ios="material:place" aurora="material:place" md="material:place" />
          <option value="0">Normal</option>
          <option value="1">Goodie</option>
          <option value="2">Food</option>
          <option value="3">Drink</option>
        </f7-list-input>
        <f7-list-item accordion-item accordion-item-opened title="Map">
          <f7-icon
            slot="media"
            ios="material:explore"
            aurora="material:explore"
            md="material:explore"
          ></f7-icon>
          <f7-accordion-content>
            <f7-block>
              <div id="mapCheckpoint" class="pin-map"></div>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-input
          :value="chklatitudeInput"
          @input="chklatitudeInput = $event.target.value"
          class="chklatitude"
          label="Latitude"
          type="number"
          step="0.000000000000001"
          placeholder="Lat"
          required
        >
          <f7-icon slot="media" ios="f7:globe" aurora="f7:globe" md="f7:globe"></f7-icon>
        </f7-list-input>
        <f7-list-input
          :value="chklongitudeInput"
          @input="chklongitudeInput = $event.target.value"
          class="chklongitude"
          label="Longitude"
          type="number"
          step="0.000000000000001"
          placeholder="Long"
          required
        >
          <f7-icon slot="media" ios="f7:globe" aurora="f7:globe" md="f7:globe"></f7-icon>
        </f7-list-input>
      </f7-list>
      <f7-block v-show="showCheckpointInput">
        <f7-row>
          <f7-col width="75">
            <f7-button fill type="submit">Save Checkpoint</f7-button>
          </f7-col>
          <f7-col width="25">
            <f7-button v-on:click="onCancelCheck" class="button-outline">Cancel</f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </form>
    <!-- Cam Popup ***************************************************************************************** -->
    <f7-popup class="cam-popup" :opened="popupOpened" @popup:close="camPopupClosed()">
      <f7-page>
        <f7-navbar :title="popTitle">
          <f7-nav-right>
            <f7-link popup-close>
              <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block class="popImg" id="popImg">
          <video id="picCaptureStream" class="picCapture hidden"></video>
          <canvas id="picCapturePhoto" class="picCapture"></canvas>
        </f7-block>
        <f7-block>
          <f7-row>
            <f7-col>
              <f7-button fill>
                <f7-icon
                  ios="material:camera_alt"
                  aurora="material:camera_alt"
                  md="material:camera_alt"
                ></f7-icon>
              </f7-button>
            </f7-col>
            <f7-col>
              <f7-button fill>
                <f7-icon
                  ios="material:collections"
                  aurora="material:collections"
                  md="material:collections"
                ></f7-icon>
              </f7-button>
            </f7-col>
            <f7-col>
              <f7-button fill v-if="checkpointPhoto">
                <f7-icon ios="material:delete" aurora="material:delete" md="material:delete"></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
        <f7-block class="pre-formatted">{{popText}}</f7-block>
      </f7-page>
    </f7-popup>
    <input
      type="file"
      id="fileElem"
      accept="image/*"
      style="display:none"
      v-on:change="handlePicFiles"
    />
  </f7-page>
</template>

<script>
import pcLogo from "../images/primus-challenge-logo-512.png";
import { createMapPointer } from "../js/map";
const fb = require("../js/fb.js");
const lg = require("../js/lang.js");
const cam = require("../js/cam.js");
require("../js/load-image/load-image.all.min.js");

export default {
  data() {
    return {
      appLang: lg.appLang,
      items: null,
      pageTitle: "Create Challenge",
      user: null,
      user_data: null,
      pcLogo: pcLogo,
      canCreate: null,
      swipeIsBusy: false,
      showChallengeSet: true,
      selectedChallenge: null,
      selectedChallengeId: "",
      challengeName: "",
      showCheckpointInput: false,
      map: null,
      checkMap: null,
      challengeNameInput: "",
      challengeDescrInput: "",
      difficultyInput: "",
      distanceInput: "",
      activateInput: "",
      latitudeInput: "",
      longitudeInput: "",
      veloInput0: "MTB",
      veloInput1: "City",
      veloInput2: "Racing",
      checkpointTextInput: "",
      chklatitudeInput: "",
      chklongitudeInput: "",
      selectedCheckpoint: null,
      selectedCheckpointId: "",
      checkPointsList: [],
      chkTypeInput: 0,
      lengthText: "Longeur env.: ",
      kmText: " km",
      seperatorText: " - ",
      checkpointsText: "Checkpoints",
      checkpointText: "Checkpoint no: ",
      checkpointPhoto: null,
      newCheckpointPhoto: false,
      checkpointPhotoUrl: null,
      checkPointSymbols: [
        "material:star",
        "material:toys",
        "material:local_dining",
        "material:local_drink"
      ],
      popupOpened: false,
      popText: "",
      popTitle: ""
    };
  },
  methods: {
    handlePicFiles() {
      this.$f7.dialog.preloader();
      var me = this;
      var f7 = this.$f7;
      var fileElem = document.getElementById("fileElem");
      console.log("file:");
      console.log(fileElem.files[0]);
      if (typeof fileElem.files[0] != "undefined") {
        loadImage(
          fileElem.files[0],
          function(img) {
            console.log(img);
            me.newCheckpointPhoto = true;
            me.checkpointPhoto = img.toDataURL("image/jpeg", 0.7);
            me.$f7.dialog.close();
          },
          {
            maxWidth: 1280,
            maxHeight: 720,
            minWidth: 1280,
            minHeight: 720,
            crop: true,
            cover: true,
            orientation: true,
            canvas: true
          } // Options
        );
      } else {
        me.$f7.dialog.close();
      }
    },
    addCheckpointPhoto: function() {
      document.getElementById("fileElem").click();
    },
    camPopupClosed: function() {
      // close cam popup
    },
    getWord: function(w) {
      return lg.getWord(w);
    },
    goBack() {
      var myRouter = this.$f7router;
      myRouter.back({
        force: true,
        ignoreCache: true
      });
    },
    searchAll(query, challenges) {
      const found = [];
      for (let i = 0; i < challenges.length; i += 1) {
        if (
          challenges[i].title.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
          query.trim() === ""
        )
          found.push(i);
      }
      return found; // return array with mathced indexes
    },
    onCancelCheck() {
      this.checkMap.deletePinOnMap();
      this.checkpointTextInput = "";
      this.chkTypeInput = 0;
      this.chklatitudeInput = "";
      this.chklongitudeInput = "";
      this.showCheckpointInput = false;
      this.checkpointPhoto = null;
      this.checkpointPhotoUrl = null;
    },
    onOpenCheckpointEdit() {
      var me = this;
      this.showCheckpointInput = true;
      this.checkMap.setAllCheckpoints(this.checkPointsList);
      setTimeout(function() {
        me.checkMap.map.updateSize();
      }, 10);
    },
    enableSort() {
      this.$f7.sortable.enable(".sortable");
    },
    deleteCheckpoint(chkNum) {
      var f7 = this.$f7;
      var me = this;
      var chkId = this.selectedChallenge.checkpoints[chkNum];
      var chkIdx = chkNum;
      f7.dialog.confirm("Are you sure?", "Delete Checkpoint", function() {
        var meIn = me;
        me.selectedChallenge.checkpoints.splice(chkIdx, 1);
        fb.checkpointsCollection
          .doc(chkId)
          .delete()
          .then(function() {
            //console.log("Document successfully deleted!");
          })
          .catch(function(error) {
            console.error("Error removing document: ", error);
          });
        //
        fb.challengesCollection
          .doc(me.selectedChallengeId)
          .update({
            checkpoints: me.selectedChallenge.checkpoints
          })
          .then(function(doc) {
            me.updateCheckpoints();
          });
      });
    },
    onShowChallengeSet() {
      var me = this;
      this.showChallengeSet = true;
      setTimeout(function() {
        me.map.map.updateSize();
      }, 10);
    },
    onCheckSort(e) {
      //console.log("sort checkpoints");
      //console.log(e);
      var me = this;
      var chkId = me.selectedChallenge.checkpoints;
      chkId.splice(e.to, 0, chkId.splice(e.from, 1)[0]);
      fb.challengesCollection
        .doc(me.selectedChallengeId)
        .update({
          checkpoints: chkId
        })
        .then(function(doc) {
          // checkpoint sorted
          me.updateCheckpoints();
        });
    },
    openExistingCheckpoint(id) {
      if (!this.swipeIsBusy) {
        //console.log("open existing checkpoint: " + id);
        var me = this;
        this.selectedCheckpointId = me.selectedChallenge.checkpoints[id];
        fb.checkpointsCollection
          .doc(me.selectedCheckpointId)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              me.selectedCheckpoint = doc.data();
              // open editor
              me.checkpointTextInput = doc.data().text;
              me.chkTypeInput = doc.data().type;
              me.chklatitudeInput = doc.data().lat;
              me.chklongitudeInput = doc.data().long;
              me.onOpenCheckpointEdit();
              if (doc.data().pic) {
                me.checkpointPhotoUrl = doc.data().pic;
                fb.getCheckpointImage(doc.data().pic, function(pic) {
                  me.checkpointPhoto = pic;
                });
              }
            }
          });
      }
    },
    onCheckpointCreate() {
      // save checkpoint
      this.$f7.dialog.preloader();
      var me = this;
      // photo actions
      if (me.newCheckpointPhoto) {
        // upload photo file
        fb.saveCheckpointImage(me.checkpointPhoto, me.checkpointPhotoUrl, me.checkpointCreateDB);
      } else {
        // no photo
        this.checkpointCreateDB(me.checkpointPhotoUrl);
      }
    },
    checkpointCreateDB(pic) {
      // save checkpoint
      var me = this;
      // photo actions
      if (me.newCheckpointPhoto) {
        // upload photo file
      }
      // db actions
      if (this.selectedCheckpoint) {
        // update checkpoint
        fb.checkpointsCollection
          .doc(me.selectedCheckpointId)
          .update({
            text: me.checkpointTextInput,
            type: me.chkTypeInput,
            lat: me.chklatitudeInput,
            long: me.chklongitudeInput,
            pic: pic
          })
          .then(function() {
            me.selectedCheckpoint = null;
            me.checkpointTextInput = "";
            me.chkTypeInput = 0;
            me.chklatitudeInput = "";
            me.chklongitudeInput = "";
            me.showCheckpointInput = false;
            me.updateCheckpoints();
          });
      } else {
        // add checkpoint
        var chkId = me.selectedChallenge.checkpoints;
        fb.checkpointsCollection
          .add({
            text: me.checkpointTextInput,
            type: me.chkTypeInput,
            lat: me.chklatitudeInput,
            long: me.chklongitudeInput,
            pic: pic
          })
          .then(function(doc) {
            //console.log("Document written with ID: ", doc.id);
            chkId.push(doc.id);
            // update challenge data
            fb.challengesCollection
              .doc(me.selectedChallengeId)
              .update({
                checkpoints: chkId
              })
              .then(function(doc) {
                // remove checkpoint data and close "add checkpoint"
                //console.log("written");
                me.checkpointTextInput = "";
                me.chkTypeInput = 0;
                me.chklatitudeInput = "";
                me.chklongitudeInput = "";
                me.showCheckpointInput = false;
                me.updateCheckpoints();
              });
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
            me.$f7.dialog.alert(error);
          });
      }
    },
    onChallengCreate() {
      var me = this;

      // form checks

      var formMessage = "";
      var formValid = true;
      var velo = [
        $("#velo0 input").prop("checked"),
        $("#velo1 input").prop("checked"),
        $("#velo2 input").prop("checked")
      ];

      if (velo[0] || velo[1] || velo[1]) {
        // one velo needs to be true
        //formValid = true;
      } else {
        formValid = false;
        formMessage += "Please select at least 1 bike";
      }

      if (!formValid) {
        this.$f7.dialog.alert(formMessage);
      } else {
        if (!this.selectedChallenge) {
          fb.challengesCollection
            .add({
              name: me.challengeNameInput,
              distance: me.distanceInput,
              difficulty: $(".difficulty option:selected").val(),
              lat: $(".latitude input").val(),
              long: $(".longitude input").val(),
              timestamp: Date.now(),
              bike: velo,
              description: me.challengeDescrInput,
              active: $("#activateChallenge input").prop("checked"),
              checkpoints: []
            })
            .then(function(doc) {
              //console.log("Document written with ID: ", doc.id);
              me.selectedChallengeId = doc.id;
              fb.challengesCollection
                .doc(me.selectedChallengeId)
                .get()
                .then(function(doc) {
                  if (doc.exists) {
                    me.selectedChallenge = doc.data();
                    me.updateCheckpoints();
                    me.showChallengeSet = false;
                  }
                });
            })
            .catch(function(error) {
              console.error("Error adding document: ", error);
              me.$f7.dialog.alert(error);
            });

          this.challengeName = this.challengeNameInput;
        } else {
          //console.log($("#activateChallenge input").prop("checked"));
          // update form
          console.log(me.challengeNameInput);
          console.log(me.distanceInput);
          console.log($(".difficulty option:selected").val());
          console.log($(".latitude input").val());
          console.log($(".longitude input").val());
          console.log(Date.now());
          console.log(velo);
          console.log(me.challengeDescrInput);
          console.log($("#activateChallenge input").prop("checked"));
          var des = "";
          if (typeof me.challengeDescrInput != "undefined") {
            des = me.challengeDescrInput;
          }

          fb.challengesCollection
            .doc(this.selectedChallengeId)
            .update({
              name: me.challengeNameInput,
              distance: me.distanceInput,
              difficulty: $(".difficulty option:selected").val(),
              lat: $(".latitude input").val(),
              long: $(".longitude input").val(),
              timestamp: Date.now(),
              bike: velo,
              description: des,
              active: $("#activateChallenge input").prop("checked")
            })
            .then(function() {
              // after update
              fb.challengesCollection
                .doc(me.selectedChallengeId)
                .get()
                .then(function(doc) {
                  if (doc.exists) {
                    me.selectedChallenge = doc.data();
                    me.showChallengeSet = false;
                    me.updateCheckpoints();
                  }
                });
            });
        }
      }
    },
    updateCheckpoints() {
      //console.log("update checkpoints");
      this.checkMap.deletePinOnMap();
      this.checkpointPhoto = null;
      this.checkpointPhotoUrl = null;
      var me = this;
      var f7 = this.$f7;
      var selChk = this.selectedChallenge.checkpoints;
      this.checkPointsList = [];
      for (var i = 0; i < selChk.length; i++) {
        fb.checkpointsCollection
          .doc(selChk[i])
          .get()
          .then(function(doc) {
            if (doc.exists) {
              me.checkPointsList.push(doc.data());
            }
          });
      }
      this.$f7.dialog.close();
    },
    getCoordsFromMap(latLong) {
      // set lat lon from map click
      this.latitudeInput = latLong[1].toFixed(15);
      this.longitudeInput = latLong[0].toFixed(15);
      //$(".latitude input").val(latLong[1].toFixed(15));
      //$(".longitude input").val(latLong[0].toFixed(15));
    },
    getCheckCoordsFromMap(latLong) {
      var me = this;
      this.chklatitudeInput = latLong[1].toFixed(15);
      this.chklongitudeInput = latLong[0].toFixed(15);
      //me.checkMap.changePinStyle('normal');
    },
    onChangeChkType() {
      //
      if (this.chklatitudeInput != "") {
        this.checkMap.setPinOnMapLatLong([
          $(".chklongitude input").val(),
          $(".chklatitude input").val()
        ]);
      }
    }
  },
  mounted() {
    var me = this;
    var f7 = this.$f7;
    f7.dialog.close();
    f7.dialog.preloader();

    // set user
    this.user_data = fb.currentUser;
    this.canCreate = fb.currentUser.canCreate;

    //map

    this.map = new createMapPointer(
      "map",
      this,
      15,
      20,
      5,
      this.getCoordsFromMap
    );
    this.checkMap = new createMapPointer(
      "mapCheckpoint",
      this,
      18,
      20,
      5,
      this.getCheckCoordsFromMap,
      "normal"
    );

    var myMap = this.map;
    var chkMap = this.checkMap;

    // listen on coord input changes
    $(".latitude input").focusout(function() {
      //console.log($(".latitude input").val());
      myMap.setPinOnMapLatLong([
        $(".longitude input").val(),
        $(".latitude input").val()
      ]);
    });
    $(".longitude input").focusout(function() {
      //console.log($(".longitude input").val());
      myMap.setPinOnMapLatLong([
        $(".longitude input").val(),
        $(".latitude input").val()
      ]);
    });

    $(".chklatitude input").focusout(function() {
      //console.log($(".chklatitude input").val());
      chkMap.setPinOnMapLatLong([
        $(".chklongitude input").val(),
        $(".chklatitude input").val()
      ]);
    });
    $(".chklongitude input").focusout(function() {
      //console.log($(".longitude input").val());
      chkMap.setPinOnMapLatLong([
        $(".chklongitude input").val(),
        $(".chklatitude input").val()
      ]);
    });

    // if edit

    var updCheckpoints = this.updateCheckpoints;

    if (this.$f7route.query.id) {
      this.pageTitle = "Edit Challenge";
      me.showChallengeSet = false;
      var challengeId = this.$f7route.query.id;
      var documentReference = fb.challengesCollection.doc(challengeId);
      documentReference
        .get()
        .then(function(doc) {
          if (doc.exists) {
            me.selectedChallenge = doc.data();
            me.selectedChallengeId = doc.id;
            me.challengeName = doc.data().name;
            me.challengeNameInput = me.selectedChallenge.name;
            me.challengeDescrInput = me.selectedChallenge.description;
            me.difficultyInput = me.selectedChallenge.difficulty;
            me.distanceInput = me.selectedChallenge.distance;
            me.latitudeInput = me.selectedChallenge.lat;
            me.longitudeInput = me.selectedChallenge.long;
            myMap.setPinOnMapLatLong([
              me.selectedChallenge.long,
              me.selectedChallenge.lat
            ]);
            $("#velo0 input").prop("checked", me.selectedChallenge.bike[0]);
            $("#velo1 input").prop("checked", me.selectedChallenge.bike[1]);
            $("#velo2 input").prop("checked", me.selectedChallenge.bike[2]);
            $("#activateChallenge input").prop(
              "checked",
              me.selectedChallenge.active
            );

            updCheckpoints();
            f7.dialog.close();
          }
        })
        .catch(function(error) {
          f7.dialog.close();
          //console.log("Error getting document:", error);
          f7.dialog.alert(error.message);
        });
    } else {
      f7.dialog.close();
    }
  }
};
</script>

<style>
.pin-map {
  height: 100vw;
  max-height: 50vh;
  width: 100%;
}
</style>
<template>
  <f7-page name="game">
    <f7-navbar :title="challengeName">
      <f7-link slot="left" @click="goBack()">
        <f7-icon icon="icon-back"></f7-icon>
      </f7-link>
    </f7-navbar>
    <f7-toolbar v-if="canCreate && showDev" position="bottom">
      <f7-block class="devScreen">
        <f7-row>
          <f7-col class="devOptions0"></f7-col>
          <f7-col class="devOptions1"></f7-col>
          <f7-col class="devOptions2"></f7-col>
          <f7-col class="devOptions3"></f7-col>
        </f7-row>
      </f7-block>
    </f7-toolbar>
    <f7-fab
      v-if="gameReady && gameModul && canCreate && gameStarted"
      position="right-bottom"
      slot="fixed"
    >
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
      <f7-fab-buttons position="top">
        <f7-fab-button @click="gameModul.pauseGame" class="pauseGame">
          <i class="icon material-icons">pause</i>
        </f7-fab-button>
        <f7-fab-button @click="gameModul.advCheckpoint">
          <i class="icon material-icons">skip_next</i>
        </f7-fab-button>
      </f7-fab-buttons>
    </f7-fab>
    <f7-block-title
      medium
      v-show="selectedChallenge && !gameStarted"
    >{{getWord('titles.readytoplay')}}</f7-block-title>
    <f7-block v-show="selectedChallenge && !gameStarted">
      <p v-if="selectedChallenge">{{selectedChallenge.description}}</p>
      <p v-if="newChallenge">{{getWord('sentences.startdescription')}}</p>
      <br />
      <p>
        <f7-button
          v-if="newChallenge"
          :href="googleNavLink"
          target="_blank"
          external
          fill
          large
        >{{getWord('buttons.startpoint')}}</f7-button>
      </p>
      <p v-if="gameReady">
        <f7-button v-on:click="onStartClicked" fill large>{{getWord('buttons.startgame')}}</f7-button>
      </p>
      <p v-if="gameReady && challengeInvert">
        <f7-button v-on:click="onStartInvClicked" fill large>{{getWord('buttons.startgameinv')}}</f7-button>
      </p>
    </f7-block>

    <div
      v-show="selectedChallenge && gameStarted"
      class="block"
      id="game"
      style="margin: 0; padding: 0"
    >
      <!-- game -->
    </div>

    <f7-popup class="game-popup" :opened="popupOpened" @popup:closed="popupClosed()">
      <f7-page>
        <f7-navbar :title="popTitle">
          <f7-nav-right>
            <f7-link popup-close>
              <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block v-show="popPic" class="popImg" id="popImg"></f7-block>
        <f7-block class="pre-formatted">{{popText}}</f7-block>
      </f7-page>
    </f7-popup>
    <f7-list
      v-show="hidden"
      media-list
      virtual-list
      :virtual-list-params="{ items, height: $theme.ios ? 63 : ($theme.md ? 73 : 46)}"
    >
      <ul>
        <f7-list-item v-for="(item, index) in images" :key="index" link="#">
          <img slot="media" :src="item.src" width="80" />
        </f7-list-item>
      </ul>
    </f7-list>
    <f7-list
      v-show="hidden"
      media-list
      virtual-list
      :virtual-list-params="{ items, height: $theme.ios ? 63 : ($theme.md ? 73 : 46)}"
    >
      <ul>
        <f7-list-item v-for="(item, index) in chkimages" :key="index" link="#">
          <img slot="media" :src="item.src" width="80" />
        </f7-list-item>
      </ul>
    </f7-list>
  </f7-page>
</template>

<script>
import pcLogo from "../images/primus-challenge-logo-512.png";
import pic0 from "../images/pic0.jpg";
import pic1 from "../images/pic1.jpg";
import pic2 from "../images/pic2.jpg";
import pic3 from "../images/pic3.jpg";
import pic4 from "../images/pic4.jpg";
import endPic from "../images/end.jpg";
import startPic from "../images/start.jpg";
import { GameFunctions } from "../js/game";
const fb = require("../js/fb.js");
const ga = require("../js/game.js");
const lg = require("../js/lang.js");

export default {
  data() {
    return {
      showDev: false,
      appLang: lg.appLang,
      items: null,
      user: null,
      user_data: null,
      pcLogo: pcLogo,
      canCreate: null,
      selectedChallenge: null,
      selectedChallengeId: "",
      currentChlId: "", // current challenge object id
      currentChl: null, // current challenge object
      challengeName: "",
      gameModul: null,
      gameReady: false,
      gameStarted: false,
      popupOpened: false,
      popText: "",
      popPic: null,
      popup_time: 5000,
      pop_timer: null,
      popTitle: "",
      altPics: [pic0, pic1, pic2, pic3, pic4],
      randomPic: [],
      gameEnd: false,
      endConfirmed: false,
      hidden: false,
      images: null,
      wakeLock: null,
      navLink: "#",
      googleNavLink: "#",
      newChallenge: true,
      chkimages: null,
      chkimagesUrl: [],
      currentCheckpoints: [],
      challengeInvert: false
    };
  },
  methods: {
    savePath(p) {
      // save new position to fb challenge object path
      var me = this;
      if (this.currentChl) {
        console.log(this.currentChl.path);
        var pathTemp = JSON.parse(JSON.stringify(me.currentChl.path));
        pathTemp.push(p);
        fb.userChallengesCollection.doc(me.currentChlId).update({
          path: pathTemp
        });
      }
    },
    devScreen: function(t, s) {
      $(".devOptions" + s).text(t);
    },
    getWord: function(w) {
      return lg.getWord(w);
    },
    onStartInvClicked() {
      // invert checkpoints
      var startpoint = this.currentCheckpoints.shift();
      this.currentCheckpoints = this.currentCheckpoints.reverse();
      this.currentCheckpoints.unshift(startpoint);
      console.log(this.currentCheckpoints);
      this.onStartClicked();
    },
    onStartClicked() {
      var me = this;
      //this.gameModul.startGame();
      //this.gameStarted = true;
      if (this.newChallenge) {
        fb.userChallengesCollection
          .add({
            challengeId: me.selectedChallengeId,
            userId: fb.currentUserId,
            timestamp: Date.now(),
            startTime: Date.now(),
            endTime: null,
            checkPointsReached: [],
            path: [],
            currentCheckpoint: 0
          })
          .then(function(doc) {
            //console.log("Document written with ID: ", doc.id);
            me.currentChlId = doc.id;
            // update user data
            fb.usersCollection
              .doc(fb.currentUserId)
              .update({
                currentCheckpoint: 0,
                currentChallenge: me.selectedChallengeId,
                currentChallengeObj: me.currentChlId
              })
              .then(function(doc) {
                //
                //console.log(fb.currentUserData.currentChallengeObj);
                //console.log(me.currentChlId);
                fb.userChallengesCollection
                  .doc(fb.currentUserData.currentChallengeObj)
                  .onSnapshot(function(doc) {
                    console.log("Current data: ", doc.data());
                    me.currentChl = doc.data();
                    me.gameModul.startGame();
                    me.gameStarted = true;
                  });
              });
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
            me.$f7.dialog.alert(error);
          });
      } else {
        fb.userChallengesCollection
          .doc(fb.currentUserData.currentChallengeObj)
          .onSnapshot(function(doc) {
            me.currentChlId = fb.currentUserData.currentChallengeObj;
            me.currentChl = doc.data();
            me.gameModul.startGame();
            me.gameStarted = true;
          });
      }
    },
    goBack() {
      this.gameModul.stopGame();

      var myRouter = this.$f7.views.main.router;

      this.$f7.dialog.confirm(this.getWord("sentences.quitgame"), function() {
        myRouter.navigate({
          url: "/",
          force: true,
          ignoreCache: true,
          clearPreviousHistory: true
        });
      });
    },
    initGameElement() {
      // init game
      var crntCheckpoint = 0;
      if (!this.newChallenge) {
        crntCheckpoint = this.user_data.currentCheckpoint;
      }
      this.gameModul = new GameFunctions(
        "game",
        this,
        this.selectedChallenge,
        this.currentCheckpoints,
        crntCheckpoint,
        this.gameCallBack
      );
    },
    preloadImages(p) {
      //
      var me = this;
      var y = 0;
      this.chkimages = [];
      this.chkimagesUrl = ["images/start.jpg"];
      (function prLoad() {
        if (y < p.length) {
          if (p[y]) {
            fb.getCheckpointImage(p[y], function(pic) {
              var tempImg = {
                src: pic
              };
              //console.log(pic);
              me.chkimages.push(tempImg);
              me.chkimagesUrl.push(pic);
              y++;
              prLoad();
            });
          } else {
            me.chkimagesUrl.push(null);
            y++;
            prLoad();
          }
        }
      })();
    },
    popupClosed() {
      clearTimeout(this.pop_timer);
      this.popupOpened = false;
      if (this.gameEnd && !this.endConfirmed) {
        this.endConfirmed = true;
        // game has ended
        this.gameEndAction();
      }
    },
    getRandomPic() {
      if (this.randomPic.length <= 0) {
        this.randomPic = this.altPics.slice();
      }
      var min = 0;
      var max = this.randomPic.length;
      var currentPic = this.randomPic.splice(
        Math.floor(Math.random() * (max - min)) + min,
        1
      );
      return currentPic;
    },
    gameEndAction() {
      this.gameModul.stopGame();

      var myRouter = this.$f7.views.main.router;

      myRouter.navigate({
        url: "/",
        force: true,
        ignoreCache: true,
        clearPreviousHistory: true
      });
    },
    // call backs from game module
    gameCallBack(message, chkpoint, chkNum) {
      //console.log(chkpoint);
      var me = this;
      var chkReached;

      if (!message) {
        //
      } else if (message == "end") {
        // open end message
        //console.log("end");
        this.popTitle = this.challengeName + " is finished !";
        this.gameEnd = true;
        this.popText = chkpoint.text;
        this.popPic = endPic;
        $(".popImg").css({
          background: "url(" + me.popPic + ") no-repeat",
          "background-size": "cover"
        });

        me.popupOpened = true;
        var finChal;
        var finChalId;
        if (fb.currentUserData.finishedChallenges) {
          finChal = fb.currentUserData.finishedChallenges;
          finChal.push(fb.currentUserData.currentChallengeObj);
          finChalId = fb.currentUserData.finishedChallengesId;
          finChalId.push(fb.currentUserData.currentChallenge);
        } else {
          finChal = [fb.currentUserData.currentChallengeObj];
          finChalId = [fb.currentUserData.currentChallenge];
        }

        // end db
        fb.usersCollection.doc(fb.currentUserId).update({
          currentCheckpoint: 0,
          currentChallenge: null,
          finishedChallenges: finChal,
          finishedChallengesId: finChalId,
          currentChallengeObj: null,
          challengesFinished: fb.currentUserData.challengesFinished + 1
        });
        chkReached = me.currentChl.checkPointsReached;
        chkReached.push(Date.now());

        fb.userChallengesCollection
          .doc(fb.currentUserData.currentChallengeObj)
          .update({
            endTime: Date.now(),
            checkPointsReached: chkReached
          });
      } else if (message == "ready") {
        // game zone ready
        this.$f7.dialog.close();
        if (!me.gameReady) {
          me.gameReady = true;
        }
      } else if (message == "geoError") {
        // geo error
        //----------------------------------
        this.$f7.dialog
          .create({
            title: this.getWord("titles.nogps"),
            content: this.getWord("sentences.nogps"),
            buttons: [
              {
                text: this.getWord("buttons.cancel"),
                onClick: function() {
                  // save user name
                  me.goBack();
                }
              },
              {
                text: this.getWord("buttons.ok"),
                onClick: function() {
                  // save user name
                  me.gameModul.playGame();
                }
              }
            ]
          })
          .open();
        //--------------------------------------------------------
      } else if (message == "chk") {
        // chk db

        if (typeof chkNum != "undefined") {
          chkReached = me.currentChl.checkPointsReached;
          chkReached.push(Date.now());
          if (chkNum == 1) {
            // challenge is started
            //console.log("challenge started: ");
            fb.usersCollection.doc(fb.currentUserId).update({
              currentCheckpoint: chkNum
            });
            fb.userChallengesCollection
              .doc(fb.currentUserData.currentChallengeObj)
              .update({
                startTime: Date.now(),
                currentCheckpoint: chkNum,
                checkPointsReached: chkReached
              });
          } else if (chkNum > 1) {
            // challenge chk update
            //console.log("challenge chk reached: " + message);
            fb.usersCollection.doc(fb.currentUserId).update({
              currentCheckpoint: chkNum
            });
            fb.userChallengesCollection
              .doc(fb.currentUserData.currentChallengeObj)
              .update({
                currentCheckpoint: chkNum,
                checkPointsReached: chkReached
              });
          }
        }

        // open checkpoint message
        if (chkNum > 0) {
          this.popTitle = "Chckpoint " + chkNum;
        } else {
          this.popTitle = "Debut du challenge";
        }

        this.popText = chkpoint.text;
        console.log(me.chkimagesUrl[chkNum]);
        if (me.chkimagesUrl[chkNum]) {
          /*
          fb.getCheckpointImage(chkpoint.pic, function(pic) {
            me.popPic = pic;
            $(".popImg").css({
              background: "url(" + pic + ") no-repeat",
              "background-size": "cover"
            });
          });
          */
          me.popPic = me.chkimagesUrl[chkNum];
          $(".popImg").css({
            background: "url(" + me.popPic + ") no-repeat",
            "background-size": "cover"
          });
        } else {
          this.popTitle = "Chckpoint " + chkNum;
          this.popPic = this.getRandomPic();
          $(".popImg").css({
            background: "url(" + me.popPic + ") no-repeat",
            "background-size": "cover"
          });
        }
        var me = this;
        me.popupOpened = true;
        this.pop_timer = setTimeout(function() {
          //console.log("time");
          me.popupOpened = false;
          clearTimeout(me.pop_timer);
        }, this.popup_time);
        $(".game-popup").click(function() {
          me.popupClosed();
        });
      }
    }
  },

  mounted() {
    //this.$f7.views.main.router.clearPreviousHistory();
    var f7 = this.$f7;
    var myRouter = this.$f7.views.main.router;
    var me = this;
    this.$f7.dialog.preloader();

    this.devScreen();

    //continue challenge
    if (this.$f7route.query.continue == 1) {
      //console.log("continue challenge");
      this.newChallenge = false;
    }

    // set user
    this.user_data = fb.currentUserData;
    this.canCreate = fb.currentUserData.canCreate;

    if (this.$f7route.query.id) {
      // prepare game
      var challengeId = this.$f7route.query.id;
      fb.challengesCollection
        .doc(challengeId)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            me.selectedChallenge = doc.data();
            me.selectedChallengeId = doc.id;
            me.challengeName = doc.data().name;
            me.challengeInvert = doc.data().inverse;
            me.navLink =
              "geo:" +
              me.selectedChallenge.lat +
              "," +
              me.selectedChallenge.long +
              "";
            me.googleNavLink =
              "https://www.google.com/maps/place/" +
              me.selectedChallenge.lat +
              "," +
              me.selectedChallenge.long +
              "";
            // load checkpoints
            var currentChk = 0;
            var chkimagesTemp = [];
            me.currentCheckpoints = [
              {
                lat: me.selectedChallenge.lat,
                long: me.selectedChallenge.long,
                text: me.getWord("sentences.startmessage"),
                type: "place",
                pic: startPic
              }
            ];
            //
            var i = 0;
            var chkLength = me.selectedChallenge.checkpoints.length;
            (function prLoad() {
              if (i < chkLength) {                
                // load & create checkpoints data
                var currentChkId = me.selectedChallenge.checkpoints[i];
                fb.checkpointsCollection
                  .doc(currentChkId)
                  .get()
                  .then(function(doc) {                    
                    if (doc.exists) {
                      
                      me.currentCheckpoints.push(doc.data());
                      if (typeof doc.data().pic != "undefined" && doc.data().pic != "" && doc.data().pic) {
                        chkimagesTemp.push(doc.data().pic);
                      } else {
                        chkimagesTemp.push(null);
                      }
                      ++i;
                      prLoad();
                    }
                  });
              } else {
                //finish data
                // allcheckpoints loaded
                console.log(me.currentCheckpoints);
                me.preloadImages(chkimagesTemp);
                me.initGameElement();
              }
            })();
            //
            /*
            for (var i = 0; i < me.selectedChallenge.checkpoints.length; i++) {
              // load & create checkpoints data
              var currentChkId = me.selectedChallenge.checkpoints[i];
              fb.checkpointsCollection
                .doc(currentChkId)
                .get()
                .then(function(doc) {
                  if (doc.exists) {
                    me.currentCheckpoints.push(doc.data());
                    if (
                      typeof doc.data().pic != "undefined" &&
                      doc.data().pic != "" &&
                      doc.data().pic
                    ) {
                      chkimagesTemp.push(doc.data().pic);
                    } else {
                      chkimagesTemp.push(null);
                    }
                    ++currentChk;
                    if (currentChk >= me.selectedChallenge.checkpoints.length) {
                      // allcheckpoints loaded
                      console.log(me.currentCheckpoints);
                      me.preloadImages(chkimagesTemp);
                      me.initGameElement();
                    }
                  }
                });
            }
            */
          }
        })
        .catch(function(error) {
          f7.dialog.close();
          f7.dialog.alert(error.message);
        });
    } else {
      // go back / no challenge selected
      f7.dialog.close();
      myRouter.back({
        url: myRouter.history[myRouter.history.length - 2],
        force: true,
        ignoreCache: true
      });
    }

    // TODO add images from firebase - images to preload

    this.images = [];
    for (var i = 0; i < this.altPics.length; i++) {
      var tempImg = { src: me.altPics[i] };
      me.images.push(tempImg);
    }
    me.images.push({ src: endPic });
    me.images.push({ src: startPic });
  }
};
</script>
<template>
  <f7-page name="followuser">
    <f7-navbar :title="pageTitle" back-link="Back"></f7-navbar>
    <f7-list
      v-if="users && !followedUser"
      media-list
      virtual-list
      :virtual-list-params="{ items, height: $theme.ios ? 63 : ($theme.md ? 73 : 46)}"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in users"
          :key="index"
          media-item
          link="#"
          :title="item.userName"
          @click="follow(index)"
        >
          <f7-icon
            v-if="item.currentChallengeObj && item.currentCheckpoint == 0"
            class="themeColorEl"
            slot="media"
            ios="material:face"
            aurora="material:face"
            md="material:face"
          ></f7-icon>
          <f7-icon
            v-if="item.currentChallengeObj && item.currentCheckpoint > 0"
            class="color-green"
            slot="media"
            ios="material:face"
            aurora="material:face"
            md="material:face"
          ></f7-icon>
          <f7-icon
            v-if="!item.currentChallengeObj"
            slot="media"
            ios="material:face"
            aurora="material:face"
            md="material:face"
          ></f7-icon>
        </f7-list-item>
      </ul>
    </f7-list>
    <f7-block v-show="followedUser" id="map" class="followMap"></f7-block>
  </f7-page>
</template>

<script>
import pcLogo from "../images/primus-challenge-logo-512.png";
import { createMapPointer } from "../js/map";
const fb = require("../js/fb.js");
const lg = require("../js/lang.js");

export default {
  data() {
    return {
      appLang: lg.appLang,
      items: null,
      user: null,
      user_data: null,
      pcLogo: pcLogo,
      canCreate: null,
      users: null,
      followedUser: null,
      followedChallenge: null,
      pageTitle: "Follow User"
    };
  },
  methods: {
    getWord: function(w) {
      return lg.getWord(w);
    },
    follow(idx) {
      var me = this;
      this.followedUser = this.users[idx];
      this.pageTitle = "Follow: " + this.followedUser.userName;
      if (me.followedUser.currentChallengeObj) {
        fb.userChallengesCollection
          .doc(me.followedUser.currentChallengeObj)
          .onSnapshot(function(doc) {
            me.followedChallenge = doc.data();
            if(me.followedChallenge.path.length > 0){
              me.updateUserPosition(
                me.followedChallenge.path[me.followedChallenge.path.length - 1]
              );
              setTimeout(function() {
                me.map.map.updateSize();
              }, 10);
            }else{
              me.followedChallenge = null;
              me.followedUser = null;
              me.$f7.dialog.alert('No data');
            }
          });
      }
    },
    updateUserPosition(pos) {
      console.log(pos);
      this.map.setPinOnMapLatLong([pos.long, pos.lat]);
    },
    getCoordsFromMap(coords) {
      //
    }
    //
  },
  mounted() {
    var me = this;
    var f7 = this.$f7;
    // set user
    this.user_data = fb.currentUserData;
    this.canCreate = fb.currentUserData.canCreate;
    this.user = fb.auth.currentUser;

    // get users
    this.users = [];

    fb.usersCollection.get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        me.users.push(doc.data());
        me.users[me.users.length - 1].id = doc.id;
        console.log(me.users);
      });
    });

    // map
    this.map = new createMapPointer(
      "map",
      this,
      18,
      20,
      5,
      this.getCoordsFromMap
    );
  }
};
</script>
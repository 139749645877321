<template>
  <f7-page name="home">
    <f7-panel left reveal resizable>
      <f7-view>
        <f7-page>
          <f7-list>
            <f7-list-item
              link="/challenges/"
              :title="getWord('buttons.challenges')"
              view=".view-main"
              class="panel-close"
            >
              <f7-icon
                slot="media"
                ios="material:directions_bike"
                aurora="material:directions_bike"
                md="material:directions_bike"
              ></f7-icon>
            </f7-list-item>
            <f7-list-item
              link="/about/"
              :title="getWord('buttons.about')"
              view=".view-main"
              class="panel-close"
            >
              <f7-icon slot="media" ios="material:info" aurora="material:info" md="material:info"></f7-icon>
            </f7-list-item>
            <f7-list-item divider :title="getWord('titles.store')"></f7-list-item>
            <f7-list-item
              link="https://primusbikecenter.ch/"
              external
              :title="getWord('buttons.store')"
              view=".view-main"
              class="panel-close"
            ></f7-list-item>
          </f7-list>
          <f7-block v-if="user && user_data" strong>
            <f7-block-header>
              {{getWord('sentences.logged')}}
              <p class="themeColorEl">"{{user_data.userName}}"</p>
              {{user.email}}
            </f7-block-header>
            <f7-button
              v-on:click="onLogoutClicked"
              fill
              class="panel-close"
            >{{getWord('buttons.logout')}}</f7-button>
          </f7-block>
          <f7-list>
            <f7-list-item divider :title="getWord('titles.language')"></f7-list-item>
            <f7-list-item
              radio
              title="EN"
              name="lang-radio"
              class="panel-close"
              id="radio-en"
              :checked="appLang == 'en'"
              @change="changeLang('en')"
            ></f7-list-item>
            <f7-list-item
              radio
              title="FR"
              name="lang-radio"
              class="panel-close"
              id="radio-fr"
              :checked="appLang == 'fr'"
              @change="changeLang('fr')"
            ></f7-list-item>
            <f7-list-item
              radio
              title="DE"
              name="lang-radio"
              class="panel-close"
              id="radio-de"
              :checked="appLang == 'de'"
              @change="changeLang('de')"
            ></f7-list-item>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-panel>
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link raised panel-open="left">
          <f7-icon ios="f7:icon-bars" aurora="f7:icon-bars" md="f7:icon-bars"></f7-icon>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Primus Challenge</f7-nav-title>
      <f7-nav-right v-if="!isPWAinBrowser && clsButton">
        <f7-link raised @click="closeApp()">
          <f7-icon ios="f7:icon-xmark" aurora="f7:icon-xmark" md="f7:icon-xmark"></f7-icon>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <!-- Page content-->
    <f7-block class="block home-logo">
      <img :src="pcLogo" />
    </f7-block>
    <div v-if="user && user_data">
      <f7-list class="list components-list">
        <f7-list-item link="#" @click="goToChallenges()" :title="getWord('buttons.challenges')">
          <f7-icon
            slot="media"
            ios="material:directions_bike"
            aurora="material:directions_bike"
            md="material:directions_bike"
          ></f7-icon>
        </f7-list-item>
        <f7-list-item
          v-if="user_data.currentChallenge"
          link="#"
          @click="openCurrentChallenge()"
          :title="getWord('buttons.continue')"
          class="themeColorEl"
        >
          <f7-icon
            slot="media"
            ios="material:directions_bike"
            aurora="material:directions_bike"
            md="material:directions_bike"
          ></f7-icon>
        </f7-list-item>
        <f7-list-item v-if="canCreate" link="/create/" :title="getWord('buttons.create')">
          <f7-icon
            slot="media"
            ios="material:explore"
            aurora="material:explore"
            md="material:explore"
          ></f7-icon>
        </f7-list-item>
        <f7-list-item v-if="canCreate" link="/followuser/" title="Follow User">
          <f7-icon slot="media" ios="material:face" aurora="material:face" md="material:face"></f7-icon>
        </f7-list-item>
      </f7-list>
    </div>
    <f7-block v-else strong>
      <f7-button fill v-on:click="onGoogleLoginClicked">{{getWord('buttons.googlelogin')}}</f7-button>
    </f7-block>
    <f7-block v-if="!user" strong>
      <f7-block-title>{{getWord('sentences.otherlogin')}}</f7-block-title>
      <form @submit.prevent="onLoginWithEmailClicked" action method="GET">
        <f7-list class="login-list" no-hairlines-md inline-labels>
          <f7-list-input
            class="email-input"
            :value="email"
            @input="email = $event.target.value"
            :label="getWord('sentences.login')"
            type="email"
            placeholder="Email address"
          />
          <f7-list-input
            :value="password"
            @input="password = $event.target.value"
            :label="getWord('sentences.password')"
            type="password"
            placeholder="Password"
          />
        </f7-list>
        <f7-button fill type="submit">{{getWord('buttons.login')}}</f7-button>
      </form>
    </f7-block>

    <f7-list>
      <f7-list-item link="/about/" :title="getWord('buttons.about')">
        <f7-icon slot="media" ios="material:info" aurora="material:info" md="material:info"></f7-icon>
      </f7-list-item>
      <f7-list-item divider :title="getWord('titles.store')"></f7-list-item>
      <f7-list-item link="https://primusbikecenter.ch/" external :title="getWord('buttons.store')"></f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import pcLogo from "../images/primus-challenge-logo-512.png";

const fb = require("../js/fb.js");
const lg = require("../js/lang.js");

export default {
  data() {
    return {
      appLang: lg.appLang,
      user: null,
      user_data: null,
      user_id: null,
      email: "",
      password: "",
      username_signup: "",
      email_signup: "",
      password_signup: "",
      showSignupForm: false,
      pcLogo: pcLogo,
      canCreate: null,
      isPWAinBrowser: true,
      clsButton: false,
      userNameInputIsInvalid: false,
      dev: " development",
      userNameAsked: false
    };
  },
  methods: {
    goToChallengesDev() {
      var me = this;

      if (this.user_data.currentChallenge) {
        // ask to continue current challenge
        this.$f7.dialog
          .create({
            title: this.getWord("titles.continue"),
            content: this.getWord("sentences.continue"),
            buttons: [
              {
                text: this.getWord("buttons.no"),
                onClick: function() {
                  // remove old challenge
                  console.log(me.user_data.currentChallenge);
                  console.log(me.user_data.currentChallengeObj);
                  fb.userChallengesCollection
                    .doc(me.user_data.currentChallengeObj)
                    .delete()
                    .then(function(doc) {
                      //
                      fb.usersCollection.doc(fb.currentUserId).update({
                        currentCheckpoint: 0,
                        currentChallenge: null,
                        currentChallengeObj: null
                      });
                    });

                  //
                  me.$f7.views.main.router.navigate({
                    url: "/challengesdev/",
                    force: true,
                    ignoreCache: true,
                    pushState: false
                  });
                }
              },
              {
                text: this.getWord("buttons.yes"),
                onClick: function() {
                  // open last challenge
                  me.$f7.views.main.router.navigate({
                    url:
                      "/game/?id=" +
                      me.user_data.currentChallenge +
                      "&continue=1",
                    force: true,
                    ignoreCache: true,
                    pushState: false
                  });
                }
              }
            ]
          })
          .open();
      } else {
        // there is no challenge open
        this.$f7.views.main.router.navigate({
          url: "/challengesdev/",
          force: true,
          ignoreCache: true,
          pushState: false
        });
      }

      /*
      this.$f7.views.main.router.navigate({
        url: "/game/?id=" + this.user_data.currentChallenge + "&continue=1",
        force: true,
        ignoreCache: true,
        pushState: false
      });
      */
    },
    goToChallenges() {
      var me = this;

      if (this.user_data.currentChallenge) {
        // ask to continue current challenge
        this.$f7.dialog
          .create({
            title: this.getWord("titles.continue"),
            content: this.getWord("sentences.continue"),
            buttons: [
              {
                text: this.getWord("buttons.no"),
                onClick: function() {
                  // remove old challenge
                  console.log(me.user_data.currentChallenge);
                  console.log(me.user_data.currentChallengeObj);
                  fb.userChallengesCollection
                    .doc(me.user_data.currentChallengeObj)
                    .delete()
                    .then(function(doc) {
                      //
                      fb.usersCollection.doc(fb.currentUserId).update({
                        currentCheckpoint: 0,
                        currentChallenge: null,
                        currentChallengeObj: null
                      });
                    });

                  //
                  me.$f7.views.main.router.navigate({
                    url: "/challenges/",
                    force: true,
                    ignoreCache: true,
                    pushState: false
                  });
                }
              },
              {
                text: this.getWord("buttons.yes"),
                onClick: function() {
                  // open last challenge
                  me.$f7.views.main.router.navigate({
                    url:
                      "/game/?id=" +
                      me.user_data.currentChallenge +
                      "&continue=1",
                    force: true,
                    ignoreCache: true,
                    pushState: false
                  });
                }
              }
            ]
          })
          .open();
      } else {
        // there is no challenge open
        this.$f7.views.main.router.navigate({
          url: "/challenges/",
          force: true,
          ignoreCache: true,
          pushState: false
        });
      }

      /*
      this.$f7.views.main.router.navigate({
        url: "/game/?id=" + this.user_data.currentChallenge + "&continue=1",
        force: true,
        ignoreCache: true,
        pushState: false
      });
      */
    },
    getWord: function(w) {
      return lg.getWord(w);
    },
    changeLang(l) {
      //
      fb.usersCollection.doc(this.user_id).update({
        lang: l
      });
      this.appLang = lg.updateLang(l);
    },
    closeApp() {
      var f7 = this.$f7;
      this.$f7.dialog
        .create({
          title: this.getWord("titles.closeapp"),
          content: "",
          buttons: [
            {
              text: this.getWord("buttons.no"),
              onClick: function() {
                // cancel
              }
            },
            {
              text: this.getWord("buttons.yes"),
              onClick: function() {
                // ok
                navigator.app.exitApp();
              }
            }
          ]
        })
        .open();
    },
    openCurrentChallenge() {
      //
      this.$f7.views.main.router.navigate({
        url: "/game/?id=" + this.user_data.currentChallenge + "&continue=1",
        force: true,
        ignoreCache: true,
        pushState: false
      });
    },
    onLoginWithEmailClicked() {
      var f7 = this.$f7;
      var email = this.email;
      var password = this.password;
      var me = this;
      // f7.dialog.preloader();
      fb.auth
        .signInWithEmailAndPassword(email, password)
        .then(res => {
          //account exists
          me.user = fb.auth.currentUser;
        })
        .catch(error => {
          //account not exists
          f7.dialog.close();
          f7.dialog.confirm(
            error.message + "<br><br>Create a new account?",
            function() {
              //
              // f7.dialog.preloader();
              fb.auth
                .createUserWithEmailAndPassword(email, password)
                .then(res => {
                  this.user = fb.auth.currentUser;
                })
                .catch(error => {
                  f7.dialog.close();
                  //console.error("Failed to create user", error);
                  f7.dialog.alert(error.message + " Please try again.", "");
                });
              //
            }
          );
        });
    },
    onSignupClicked(e) {
      var f7 = this.$f7;
      // f7.dialog.preloader();
      e.preventDefault();
      fb.auth
        .createUserWithEmailAndPassword(this.email, this.password)
        .catch(error => {
          f7.dialog.close();
          //console.error("Failed to create user", error);
          f7.dialog.alert(error.message + " Please try again.", "");
        });
    },
    onLogoutClicked() {
      var f7 = this.$f7;
      // f7.dialog.preloader();
      fb.auth.signOut().catch(error => {
        f7.dialog.close();
        //console.error("Error while trying to logout user", error);
      });
    },
    onGoogleLoginClicked() {
      var f7 = this.$f7;
      // f7.dialog.preloader();
      var provider = new fb.fbase.auth.GoogleAuthProvider();
      fb.auth.signInWithRedirect(provider);
    },
    updateUserView(us, cc) {
      console.log("is transmit!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log(us);
      this.user_data = us;
      this.canCreate = cc;
      fb.updateUser(us);
    },
    createUserName() {
      //
      var me = this;
      var f7 = this.$f7;
      this.userNameInputIsInvalid = false;
      f7.dialog
        .create({
          title: "Nom d'utilisateur:",
          content:
            '<div class="dialog-input-field item-input"><div class="item-input-wrap"><input type="text" class="dialog-input"><div class="item-input-info dialog-info">4 à 12 characters</div></div></div>',
          buttons: [
            {
              text: "OK",
              onClick: function() {
                // save user name
                if (!me.userNameInputIsInvalid) {
                  //console.log($(".dialog-input").val());
                  me.user_data.userName = $(".dialog-input").val();
                  fb.usersCollection
                    .doc(me.user.uid)
                    .update({
                      userName: $(".dialog-input").val()
                    })
                    .then(function() {
                      // after update
                      fb.usersCollection
                        .doc(me.user.uid)
                        .get()
                        .then(function(doc) {
                          me.user_data = doc.data();
                          me.updateUserView(me.user_data, me.canCreate);
                        });
                    });
                  fb.userNamesCollection
                    .add({
                      name: $(".dialog-input").val(),
                      id: me.user.uid
                    })
                    .then(function(doc) {
                      //console.log("U Name written with ID: ", doc.id);
                    })
                    .catch(function(error) {
                      //console.error("Error adding document: ", error);
                    });
                }
              }
            }
          ],
          on: {
            open: function() {
              $(".dialog-button").css("display", "none");
              $(".dialog-input").on("input", function(e) {
                $(".dialog-input").val(
                  $(".dialog-input")
                    .val()
                    .toLowerCase()
                );
                if ($(".dialog-input").val().length > 12) {
                  $(".dialog-input").val(
                    $(".dialog-input")
                      .val()
                      .substr(0, 12)
                  );
                }
                var validMessage = me.checkUserName($(".dialog-input").val());
                me.userNameInputIsInvalid = validMessage;
                if (validMessage) {
                  // invalid
                  //console.log(validMessage);
                  $(".dialog-input").css("color", "var(--f7-theme-color)");
                  $(".dialog-info").text(validMessage);
                  $(".dialog-info").css("color", "var(--f7-theme-color)");
                  $(".dialog-button").css("display", "none");
                } else {
                  // valid
                  //console.log("valid");
                  $(".dialog-input").css("color", "inherit");
                  $(".dialog-info").css("color", "inherit");
                  $(".dialog-info").text("");
                  $(".dialog-button").css("display", "block");
                }
              });
            }
          }
        })
        .open();
    },
    checkUserName(usName) {
      // user minimum 4 chars
      var me = this;
      var invalidName = null;
      //console.log("create user name: " + usName);
      //console.log(usName.length);
      if (usName.length >= 4) {
        // check if name exist
        fb.userNamesCollection
          .where("name", "==", usName)
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
              //console.log(doc.id, " => ", doc.data());
              $(".dialog-button").css("display", "none");
              invalidName = "ce nom est déjà utilisé !";
              $(".dialog-input").css("color", "var(--f7-theme-color)");
              $(".dialog-info").text(invalidName);
              $(".dialog-info").css("color", "var(--f7-theme-color)");
              me.userNameInputIsInvalid = invalidName;
            });
            return invalidName;
          });
      } else {
        invalidName = "min. 4 chars !";
        return invalidName;
      }

      // return valid
    }
  },
  mounted() {
    var $$ = this.Dom7;
    var me = this;
    // check if pwa or browser

    //console.log(this.$f7);

    this.isPWAinBrowser = true;
    // replace standalone with fullscreen or minimal-ui according to your manifest
    if (
      matchMedia("(display-mode: fullscreen)").matches ||
      matchMedia("(display-mode: standalone)").matches ||
      matchMedia("(display-mode: minimal-ui)").matches
    ) {
      // Android and iOS 11.3+
      this.isPWAinBrowser = false;
    } else if ("standalone" in navigator) {
      // useful for iOS < 11.3
      this.isPWAinBrowser = !navigator.standalone;
    }

    var f7 = this.$f7;

    // fired when user logs in or out
    var upd = this.updateUserView;
    fb.auth.onAuthStateChanged(user => {
      f7.dialog.close();
      this.user = user;
      //console.log(user);
      //

      if (user != null) {
        fb.updateUserId(user.uid);
        this.user_id = user.uid;
        var documentReference = fb.usersCollection.doc(user.uid);

        documentReference.get().then(function(documentSnapshot) {
          if (documentSnapshot.exists) {
            // do something with the data
            me.user_data = documentSnapshot.data();
          } else {
            //console.log("create user data");
            documentReference.set({
              displayName: user.displayName,
              email: user.email,
              challengesFinished: 0,
              currentChallenge: null,
              currentCheckpoint: 0,
              dateJoined: Date.now(),
              canCreate: false,
              photoURL: user.photoURL
            });
          }
        });
        fb.usersCollection.doc(user.uid).onSnapshot(function(doc) {
          var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          if (doc.data()) {
            console.log(doc.data);
            //console.log(doc.data());
            me.user_data = doc.data();
            me.canCreate = me.user_data.canCreate;
            upd(me.user_data, me.canCreate);
            console.log(
              "**************************************************************"
            );
            console.log(me.user_data);
            if (typeof me.user_data.userName == "undefined") {
              // create user name
              if (!this.userNameAsked) {
                this.userNameAsked = true;
                me.createUserName(me.user_data);
              }
            } else {
              // has user name
              //console.log(doc.data().userName);
            }
            if (typeof me.user_data.lang == "undefined") {
              fb.usersCollection.doc(me.user.uid).update({
                lang: me.appLang
              });
            } else {
              console.log("language: " + me.appLang);
              me.appLang = lg.updateLang(me.user_data.lang);
            }
          }
        });
        //console.log(this.user_data);
      }
    });
  }
};
</script>

<style>
</style>
import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

// firebase init goes here
const config = {
  apiKey: "AIzaSyBS-Z_P-X4rV9NOr4d3DsVaj1P60s4Y1F4",
  authDomain: "primus-challenge.firebaseapp.com",
  databaseURL: "https://primus-challenge.firebaseio.com",
  projectId: "primus-challenge",
  storageBucket: "primus-challenge.appspot.com",
  messagingSenderId: "938162931177",
  appId: "1:938162931177:web:8c524c92a0c58ddeebb778"
};

firebase.initializeApp(config);
firebase.auth().languageCode = 'fr';

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence();


// firebase utils
const fbase = firebase;
const db = firebase.firestore();
const auth = firebase.auth();
const otherAuth = firebase.auth;
const storage = firebase.storage();
var currentUserData = null;
var currentUser = auth.currentUser;
var currentUserId = '';



// firebase collections
const usersCollection = db.collection('users');
const userNamesCollection = db.collection('user_names');
const challengesCollection = db.collection('challenges');
const userChallengesCollection = db.collection('user_challenges');
const checkpointsCollection = db.collection('checkpoints');

function updateUser(us) {
  currentUser = us;
  currentUserData = us;
}

function updateUserId(usid) {
  currentUserId = usid;
}

function saveCheckpointImage(image, picUrl, cb) {
  console.log(image);
  var fileToSave;
  if (!picUrl) {
    var contentArray = image.split(',');
    var contentMimeType = contentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];
    var contentFileType = contentMimeType.split('/')[1];
    fileToSave = 'checkpoint-images/chk' + Date.now() + '.' + contentFileType;
  } else {
    fileToSave = picUrl;
  }
  console.log(fileToSave);
  var checkpointImagesRef = storage.ref().child(fileToSave);
  checkpointImagesRef.putString(image, 'data_url').then(function (snapshot) {
    cb(checkpointImagesRef.fullPath);
  });
}

function getCheckpointImage(image, cb) {
  storage.ref(image).getDownloadURL().then(function (url) {
    cb(url);
  });

}

export {
  fbase,
  db,
  auth,
  otherAuth,
  currentUser,
  currentUserId,
  currentUserData,
  usersCollection,
  userNamesCollection,
  challengesCollection,
  checkpointsCollection,
  userChallengesCollection,
  updateUser,
  updateUserId,
  saveCheckpointImage,
  getCheckpointImage,
};
import HomePage from '../pages/home.vue';
import AboutPage from '../pages/about.vue';
import CreateChallenge from '../pages/create.vue';
import Challenges from '../pages/challenges.vue';
import Game from '../pages/game.vue';
import FollowUser from '../pages/followuser.vue';

import ChallengesDev from '../pages/challengesdev.vue';
import GameDev from '../pages/gamedev.vue';

import NotFoundPage from '../pages/404.vue';

var routes = [{
    path: '/',
    component: HomePage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/create/',
    component: CreateChallenge,
  },
  {
    path: '/challenges/',
    component: Challenges,
  },
  {
    path: '/game/',
    component: Game,
  },
  {
    path: '/followuser/',
    component: FollowUser,
  },
  {
    path: '/challengesdev/',
    component: ChallengesDev,
  },
  {
    path: '/gamedev/',
    component: GameDev,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
  
  
];

export default routes;
<template>

  <f7-page name="about">
    <f7-navbar :title="getWord('titles.about')" back-link="Back"></f7-navbar>
    <f7-block><span v-html="getWord('sentences.about')"></span></f7-block>
  </f7-page>
</template>

<script>
import pcLogo from "../images/primus-challenge-logo-512.png";
const fb = require('../js/fb.js');
const lg = require("../js/lang.js");

export default {
  data() {
    return {
      appLang: lg.appLang,
      user: null,
      user_data: null,
      pcLogo: pcLogo,
      canCreate: null,
    };
  },
  methods: {
    getWord: function(w) {
      return lg.getWord(w);
    },
    //
  },
  mounted() {
    var f7 = this.$f7;

    // set user
    this.user_data = fb.currentUser;
    this.canCreate = fb.currentUser.canCreate;
  }
};

</script>
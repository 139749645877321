// openlayers imports

import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import Vector from 'ol/layer/Vector';
import {
  XYZ,
  OSM,
  TileDebug
} from 'ol/source';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import Point from 'ol/geom/Point';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Icon
} from 'ol/style';
import Text from "ol/style/Text";
import {
  defaults as defaultControls,
  Control
} from 'ol/control';
import Overlay from 'ol/Overlay';
import LineString from 'ol/geom/LineString';
import {
  fromLonLat,
  toLonLat,
  transform
} from 'ol/proj';
import {
  getDistance
} from 'ol/sphere';
import {
  defaults as defaultInteractions
} from 'ol/interaction';

import pcLogo from "../images/primus-challenge-logo-512.png";
import startPic from "../images/start.jpg";
import indicatorIcon from '../images/indicator.png';
import geoMarkerMove from '../images/bike-top-view-small.png';
import geoMarkerStand from '../images/bike-top-view-small.png';
import speedIcon from '../images/speed.png';

import chkSound from '../static/sound/bell.wav';
import {
  parseComponent
} from 'vue-template-compiler';

import Kompas from 'kompas';

const compass = new Kompas();

const fb = require("./fb.js");


const checkSympols = {
  "syplace": "place",
  "sy0": "star",
  "sy1": "toys",
  "sy2": "local_dining",
  "sy3": "local_drink",
  "systart": "alarm",
  "syend": "stars",
};

let wakeLock = null;

var activateWakeLock = function () {

  if ('WakeLock' in window && 'request' in window.WakeLock) {

    const requestWakeLock = () => {
      const controller = new AbortController();
      const signal = controller.signal;
      window.WakeLock.request('screen', {
          signal
        })
        .catch((e) => {
          if (e.name === 'AbortError') {
            //console.log('Wake Lock was aborted');
          } else {
            console.error('error');
          }
        });
      //console.log('Wake Lock is active');
      return controller;
    };

    wakeLock = requestWakeLock();

    return 1;

  } else if ('wakeLock' in navigator && 'request' in navigator.wakeLock) {

    const requestWakeLock = async () => {
      try {
        wakeLock = await navigator.wakeLock.request('screen');
        wakeLock.addEventListener('release', () => {
          //console.log('Wake Lock was released');
        });
        //console.log('Wake Lock is active');
      } catch (e) {
        console.error('error');
      }
    };

    requestWakeLock();

    return 2;

  } else {
    console.log('Wake Lock API not supported.');
    return 0;
  }
}



class GameFunctions {
  constructor(gameZone, par, cChallenge, chkPoints, cCheckpoint, cb) {

    var self = this;
    var parent = par;
    var callBack = cb;
    // settings
    var gameWidth = 100;
    var gameHeight = 100;
    var dashSize = 25;
    var mapSize = 75;
    var gameMap = 'map';
    var gameDash = 'dash';

    var app = parent.$f7;

    //game
    var currentGameChallenge = cChallenge;
    var currentCheckpoint;
    if (cCheckpoint > 0) {
      currentCheckpoint = cCheckpoint + 1;
    } else {
      currentCheckpoint = cCheckpoint;
    }
    var checkPoints = chkPoints;
    var currentChallengeData;
    var checkFeature;
    var chkPlaceLayer, currentCheckpointData;
    var currentCheckpointPosition, currentMapPosition, currentRealPosition, currentCheckPointDistance;
    var distanceResizer = 250; // in meters -> begin to resize direction indicator
    var checkpointReachedDistance = 10; // 10m
    var minCheckpointSize = 0.4;
    var minDirSize = 0.3;
    var challengeEnded = false;
    var startTime = 0;
    var challengeStarted = false;
    var gameIsRunning = false;

    // rel vars
    var gameZone = gameZone;
    var gameZoneEl = document.getElementById(gameZone);
    var gameMapEl, gameDashEl;
    var landscape;
    var currentRotationRad = 0;
    var currentSpeed = 0;

    // sensor
    var compassRotation;
    var compassRotationDeg;
    var compassRotationExist = false;

    // map settings

    var map;
    var markerEl, centerMarker;
    var startCoords = [6.524637, 46.562143];
    var defaultZoom = 17;
    var deltaMean = 500; // the geolocation sampling period mean in ms

    var localisationAccurate = false;

    var wl; // wakeLock

    // map view

    var view = new View({
      center: fromLonLat(startCoords),
      minZoom: defaultZoom,
      maxZoom: defaultZoom,
      zoom: defaultZoom
    });

    // view url map
    var primusTileLayer = new TileLayer({
      /*
      source: new XYZ({
      	url: 'https://test.stanlight.ch/map/{z}/{x}/{y}.png'
      }),

      source: new XYZ({
        urls: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png"]
      })


      source: new XYZ({
      	urls: ["https://a.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png", "https://b.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png", "https://c.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png"]
      })    

      source: new OSM()

      */

    });


    // temp debug layer
    var tileLayerDebug = new TileLayer({
      source: new TileDebug({})
    });

    // direction indicator
    var features = [];

    var dirIconStyle = new Style({
      image: new Icon(({
        anchor: [0.5, 0.5],
        src: indicatorIcon,
        scale: 1
      }))
    });

    currentRealPosition = transform(startCoords, 'EPSG:4326', 'EPSG:3857');

    var dirFeature = new Feature({
      geometry: new Point(currentRealPosition)
    });

    dirFeature.setStyle(dirIconStyle);
    features.push(dirFeature);
    var vectorDirSource = new VectorSource({
      features: features
    });
    var dirLayer = new Vector({
      opacity: 0.5,
      source: vectorDirSource
    });

    // LineString to store the different geolocation positions. This LineString
    // is time aware.
    // The Z dimension is actually used to store the rotation (heading).
    var positions = new LineString([], 'XYZM');

    // geo localisation
    var geolocation = new Geolocation({
      projection: view.getProjection(),
      trackingOptions: {
        maximumAge: 10000,
        enableHighAccuracy: true,
        timeout: 5000
      }
    });

    var setUpGameZone = function (el) {

      //createCompass();

      // general zone

      el.style.height = gameHeight + "%";
      el.style.width = gameWidth + "%";

      // insert game elements

      el.innerHTML = '<audio id="checkpointSound" src="' + chkSound + '" preload="auto" class="hidden"> </audio><div id="' + gameMap + '" class="' + gameMap + '"></div><img id="geolocation_marker" src="' + geoMarkerStand + '" /><div id="' + gameDash + '" class="' + gameDash + '"><div class="chip"><div class="chip-media"><img src="' + speedIcon + '" alt="speed" height="24" width="24" style="max-width: unset !important; max-height: unset !important;" /></div><div class="chip-label" id="speed">0 km/h</div></div><div class="chip"><div class="chip-media"><i class="icon material-icons">flag</i></div><div class="chip-label" id="dist">0 m</div></div><div class="chip"><div class="chip-media"><i class="icon material-icons">timer</i></div><div class="chip-label" id="timer">00:00:00</div></div><div class="chip"><div class="chip-media"><i class="icon material-icons">arrow_forward</i></div><div class="chip-label" id="distance">0 km</div></div></div></div>';
      gameMapEl = document.getElementById(gameMap);
      gameDashEl = document.getElementById(gameDash);

      gameMapEl.style.position = "absolute";
      gameDashEl.style.position = "absolute";
      gameDashEl.style.background = "#111111";
      gameDashEl.style.color = "#cccccc";

      resizeGameZone();

      var key = 'pk.eyJ1Ijoid2Vic3RhbjcyIiwiYSI6ImNrNnYxbGYzMDBhcHEzZW9hbjJsdDl0YWEifQ.UF3TpISrPrdwL74G3lUTUA';
      //mapbox://styles/webstan72/ck7un77zc0zyr1ikz3q6z18zj

      

      // creating the map
      map = new Map({
        interactions: [],
        layers: [
          new TileLayer({
            source: new XYZ({
              //url: 'https://api.mapbox.com/v4/mapbox.outdoors/{z}/{x}/{y}.png' + '?access_token=' + key,
              urls: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png"],
            })
          }),
          /*
                  new TileLayer({
                    source: new TileDebug()
                  })*/
        ],
        target: gameMap,
        controls: [],
        view: view
      });

      // Geolocation marker
      markerEl = document.getElementById('geolocation_marker');
      centerMarker = new Overlay({
        position: fromLonLat(startCoords),
        positioning: 'center-center',
        element: markerEl,
        stopEvent: false
      });

      map.addOverlay(centerMarker);

      // direction indicator
      map.addLayer(dirLayer);

      view.setCenter(getCenterWithHeading(fromLonLat(startCoords), currentRotationRad, view.getResolution()));

      setupChallenge();

      // temp
      /*
      map.on('click', function (event) {
      	getTileCords(event.coordinate);
      });
      */

    }

    var getTileCords = function (pos) {
      var source = new OSM();
      const grid = source.getTileGrid();
      const tileCord = grid.getTileCoordForCoordAndZ(pos, view.getZoom());
    }

    // resize game elements on windows size change

    var resizeGameZone = function () {
      if (gameZoneEl.clientWidth < gameZoneEl.clientHeight) {
        landscape = false;
      } else {
        landscape = true;
      }

      if (landscape) {
        // landscape			
        gameMapEl.style.height = "100%";
        gameMapEl.style.width = mapSize + "%";
        gameMapEl.style.left = dashSize + "%";
        gameMapEl.style.top = "0";

        $('.dash .chip').css("min-width", "100%");
        $('.dash .chip').css("margin-bottom", "var(--dashPad)");
        $('.dash .chip').css("margin-top", "0");
        $('.dash .block-title').css("margin", "0 0 32px");

        gameDashEl.style.width = "calc(" + dashSize + "%" + " - 2*var(--dashPad))";
        gameDashEl.style.height = "calc(100%" + " - 2*var(--dashPad))";
        gameDashEl.style.background = "#111111";

      } else {
        // portrait			
        gameMapEl.style.width = "100%";
        gameMapEl.style.height = "100%";
        gameMapEl.style.top = "0";
        gameMapEl.style.left = "0";

        $('.dash .chip').css("min-width", "calc(50% - var(--f7-chip-padding-horizontal))");
        $('.dash .chip').css("margin-bottom", "calc(var(--dashPad) / 2)");
        $('.dash .chip').css("margin-top", "calc(var(--dashPad) / 2)");
        $('.dash .block-title').css("margin", "0 0 20px");

        gameDashEl.style.height = "auto";
        gameDashEl.style.width = "calc(100%" + " - 2*var(--dashPad))";
        gameDashEl.style.background = "rgba(17,17,17,0.8)";
      }

      if (typeof map !== 'undefined') {
        map.updateSize();
        view.setCenter(getCenterWithHeading(fromLonLat(startCoords), currentRotationRad, view.getResolution()));
      }

      // resize the game on screen size change
      window.onresize = resizeGameZone;
    }

    // recenters the view by offsetting the given coordinates
    var getCenterWithHeading = function (position, rotation, resolution) {
      var size = map.getSize();
      var width = size[0];
      var height = size[1];
      return [
        position[0] - Math.sin(rotation) * height * resolution * 1 / 4,
        position[1] + Math.cos(rotation) * height * resolution * 1 / 4
      ];
    }


    // Listen to position changes
    geolocation.on('change', function () {
      positionChanged();
    });

    geolocation.on('error', function (error) {
      // TODO app.dialog.alert(error);
      if (gameIsRunning) {
        callBack('geoError');
      }

    });

    var accurateSpeed = 1;

    compass.on('heading', function (heading) {
      compassRotationExist = true;
      compassRotationDeg = heading;
      compassRotation = (Math.PI / 180 * heading);
    });

    var speedData = [];
    var headingData = [];
    var neededDataLength = 5;
    var threshold = 0.3;

    var analyzeGeoAccuracy = function (s, h) {
      //
      var analyze = true;

      if (!localisationAccurate) {
        speedData.push(s);
        headingData.push(h);
        if (speedData.length < neededDataLength) {
          analyze = false;
        } else if (speedData.length >= neededDataLength) {
          var isOk = false;
          for (var i = 0; i < neededDataLength - 1; i++) {
            var thresh = Math.abs(speedData[i] - speedData[i + 1])
            if (speedData[i] < accurateSpeed / 1.5 || speedData[i + 1] < accurateSpeed / 1.5) {
              isOk = false;
              break;
            }
            if (speedData[i] * threshold > thresh) {
              isOk = true;
            } else {
              isOk = false;
              break;
            }
          }
          if (isOk) {
            analyze = true;
            localisationAccurate = true;
          }
          speedData.shift();
        }
      }

      if (s < accurateSpeed) {
        analyze = false;
      }
      return analyze
    }

    var heading = 0;
    var storageTime = 0; // last position stored in fb
    var storageFrequence = 30000; // save all 5 sec in fb

    var positionChanged = function () {
      var position = geolocation.getPosition();
      currentRealPosition = position;
      var accuracy = geolocation.getAccuracy();
      var speed = geolocation.getSpeed() || 0;

      var currentAccuracy = analyzeGeoAccuracy(speed, geolocation.getHeading() || 0);

      if (compassRotationExist && !currentAccuracy) {
        heading = compassRotation;
      } else if (currentAccuracy) {
        heading = geolocation.getHeading() || 0;
      }

      currentSpeed = speed;
      var m = Date.now();

      //

      addPosition(position, heading, m, speed);

      var coords = positions.getCoordinates();
      var len = coords.length;
      if (len >= 2) {
        deltaMean = (coords[len - 1][3] - coords[0][3]) / (len - 1);
      }

      // save to firebase
      if (speed >= 1 && (m - storageTime) > storageFrequence) {

        var pos = toLonLat(position);
        parent.savePath({
          speed: speed,
          lat: pos[1],
          long: pos[0],
          time: m,
          accuracy: accuracy
        });
        storageTime = m;
      }

    }

    var addPosition = function (position, heading, m, speed) {

      var x = position[0];
      var y = position[1];
      var fCoords = positions.getCoordinates();
      var previous = fCoords[fCoords.length - 1];
      var prevHeading = previous && previous[2];

      if (prevHeading) {
        var headingDiff = heading - mod(prevHeading);

        // force the rotation change to be less than 180°
        if (Math.abs(headingDiff) > Math.PI) {
          var sign = (headingDiff >= 0) ? 1 : -1;
          headingDiff = -sign * (2 * Math.PI - Math.abs(headingDiff));
        }
        heading = prevHeading + headingDiff;
      }
      positions.appendCoordinate([x, y, heading, m]);

      // only keep the 20 last coordinates
      positions.setCoordinates(positions.getCoordinates().slice(-20));

      // FIXME use speed instead
      if (heading && speed) {
        markerEl.src = geoMarkerMove;
      } else {
        markerEl.src = geoMarkerStand;
      }

    }

    var previousM = 0;
    var to;
    var lastRot = 0;
    var turnspeed;

    var updateSpeed = 20;

    var updateView = function () {
      //
      //parent.devScreen(localisationAccurate, 3);

      wl = activateWakeLock();
      clearTimeout(to);
      // use sampling period to get a smooth transition
      var m = Date.now() - deltaMean * 1.5;
      m = Math.max(m, previousM);
      previousM = m;
      // interpolate position along positions LineString
      var c = positions.getCoordinateAtM(m, true);
      if (c) {
        if (currentSpeed > accurateSpeed && localisationAccurate) {
          currentRotationRad = -c[2];
          lastRot = currentRotationRad;
        } else if (compassRotationExist) {

          var heading = compassRotation;
          var prevHeading = -lastRot;

          var headingDiff = heading - mod(prevHeading);

          // force the rotation change to be less than 180°
          if (Math.abs(headingDiff) > Math.PI) {
            var sign = (headingDiff >= 0) ? 1 : -1;
            headingDiff = -sign * (2 * Math.PI - Math.abs(headingDiff));
          }
          heading = prevHeading + headingDiff;

          var head = prevHeading + (heading - prevHeading) * 0.1;

          currentRotationRad = -head;

          lastRot = currentRotationRad;

          //parent.devScreen(lastRot.toFixed(4), 1);
          //parent.devScreen(currentRotationRad.toFixed(4), 2);

        } else {
          currentRotationRad = lastRot;
        }
        currentMapPosition = getCenterWithHeading(c, currentRotationRad, view.getResolution());
        view.setCenter(currentMapPosition);
        view.setRotation(currentRotationRad);
        centerMarker.setPosition(c);
        dirFeature.setGeometry(new Point(c));
        showDirection(c);
      }

      updateDash();

      // refresh view after deltaMean ms
      if (gameIsRunning) {
        to = setTimeout(function () {
          updateView();
        }, updateSpeed);
      }
    }

    var showDirection = function (c) {
      if (gameIsRunning) {
        var dirIcon = dirFeature.getStyle();
        var dirIconImage = dirIcon.getImage();
        var checkIcon = checkFeature.getStyle();
        // get distance to checkpoint
        var p1 = toLonLat(c);
        var p2 = toLonLat(currentCheckpointPosition);
        currentCheckPointDistance = getDistance(p1, p2);
        // get angle to checkpoint
        var dx = currentCheckpointPosition[0] - c[0];
        var dy = currentCheckpointPosition[1] - c[1];
        var angle = Math.atan2(dx, dy);
        dirIconImage.setRotation(angle + currentRotationRad);

        if (currentCheckPointDistance < distanceResizer) {
          var currentSize = currentCheckPointDistance * (1 / distanceResizer);
          if (currentSize > minCheckpointSize) {
            //checkIconImage.setScale(currentSize);
          } else {
            //checkIconImage.setScale(minCheckpointSize);
          }
          if (currentSize > minDirSize) {
            dirIconImage.setScale(currentSize);
          } else {
            dirIconImage.setScale(minDirSize);
          }
        } else {
          dirIconImage.setScale(1);
          //checkIconImage.setScale(1);
        }

        // check if reached check point
        if (currentCheckPointDistance < checkpointReachedDistance && parent.currentChl && !challengeEnded) {
          // next checkpoint
          checkpointReached();
        }
      }
    }

    var updateDash = function () {

      if (currentCheckpoint > 1 && parent.currentChl) {
        // start challenge if came back
        if (typeof parent.currentChl.startTime != 'undefined') {
          //
          startTime = parent.currentChl.startTime;
          challengeStarted = true;
        }

      }

      if (gameIsRunning) {
        //console.log(currentSpeed);
        if (currentSpeed > 0) {
          //console.log('dash: speed');
          document.getElementById('speed').innerHTML = Math.round(currentSpeed * 3.6) + ' km/h';
        }
        if (currentCheckPointDistance > 0) {
          //console.log('dash: distance');
          if (currentCheckPointDistance > 1000) {
            document.getElementById('dist').innerHTML = (currentCheckPointDistance / 1000).toFixed(2) + ' km';
          } else {
            document.getElementById('dist').innerHTML = Math.round(currentCheckPointDistance) + ' m';
          }
        }
        if (challengeStarted) {
          //console.log('dash: time');
          var tempTime = Date.now();
          document.getElementById('timer').innerHTML = msToTime(tempTime - startTime);
        }
      }
    }

    //-------------------------------------------------------------------------------------------------------------
    var runGame = function () {

      gameIsRunning = true;
      speedData = [];
      headingData = [];

      wl = activateWakeLock();
      //console.log(wl);

      $(".pauseGame i").text('pause');
      compass.watch();
      callBack();
      map.render();
      geolocation.setTracking(true); // Start position tracking
      resizeGameZone();
      updateView();

    }

    var pauseGame = function () {
      // Geolocation Control

      gameIsRunning = false;
      localisationAccurate = false;

      if (wl == 1) {
        wakeLock.abort();
        wl = null;
      } else if (wl == 2) {
        wakeLock.release();
        wl = null;
      }

      $(".pauseGame i").text('play_arrow');
      compass.clear();
      geolocation.setTracking(false);
      clearTimeout(to);

    }

    //-------------------------------------------------------------------------------------------------------------

    var setupCheckpoint = function () {

      if (currentChallengeData.path.length > currentCheckpoint) {

        // set next checkpoint        
        currentCheckpointData = currentChallengeData.path[currentCheckpoint];
        currentCheckpointPosition = fromLonLat([currentCheckpointData.long, currentCheckpointData.lat]);

        //------------------------------------------------------------------------------------------------

        var pos = currentCheckpointPosition
        var pointText;

        if (currentCheckpoint == 0) {
          pointText = "S";
          var currentPinT = 'syplace';
        } else if (currentCheckpoint == 1) {
          pointText = "1";
          var currentPinT = 'systart';
        } else if (currentCheckpoint == currentChallengeData.path.length - 1) {
          pointText = "E";
          var currentPinT = 'syend';
        } else {
          pointText = currentCheckpoint;
          var currentPinT = 'sy' + currentCheckpointData.type;
        }

        var features = [];

        checkFeature = new Feature({
          geometry: new Point(pos)
        });

        var bgColor = 'rgb(227, 6, 19)';

        var iconStyle = new Style({
          text: new Text({
            text: checkSympols[currentPinT],
            font: "normal 72px Material Icons",
            offsetY: -31,
            scale: 1,
            fill: new Fill({
              color: bgColor
            }),
          }),
          zIndex: 2
        });

        var shadowStyle = new Style({
          text: new Text({
            text: checkSympols[currentPinT],
            font: "normal 72px Material Icons",
            offsetY: -31,
            scale: 1,
            rotation: 0.65,
            fill: new Fill({
              color: 'rgba(0, 0, 0, 0.3)'
            }),
          }),
          zIndex: 1
        });

        var pointStyle = new Style({
          text: new Text({
            text: 'adjust',
            font: "normal 36px Material Icons",
            fill: new Fill({
              color: bgColor
            }),
          }),
          zIndex: 0
        });

        checkFeature.setStyle([iconStyle, shadowStyle, pointStyle]);
        features.push(checkFeature);

        var vectorSource = new VectorSource({
          features: features
        });

        chkPlaceLayer = new Vector({
          source: vectorSource
        });

        map.addLayer(chkPlaceLayer);

        if (!parent.gameReady) {
          callBack('ready');
        }

        showDirection(currentRealPosition);

        //
      } else {
        // last checkpoint reached ***WIN***
        if (!challengeEnded) {

          //currentCheckpointData = currentChallengeData.path[-1];

          challengeEnded = true;
          challengeStarted = false;

          pauseGame(); // true = off

          // end popup

          callBack('end', currentCheckpointData);

        }
      }
    }

    //-------------------------------------------------------------------------------------------------------------

    var setupChallenge = function () {

      //console.log('where is text');

      currentChallengeData = {
        path: null
      };

      currentChallengeData.path = checkPoints;

      setupCheckpoint();
    }

    //-------------------------------------------------------------------------------------------------------------

    var photoPop;
    var photoTimer;
    var checkpointReached = function () {
      checkpointSound.pause();
      checkpointSound.currentTime = 0;
      checkpointSound.play();
      if (currentCheckpoint == 1) {
        //start challenge + timer
        startTime = Date.now();
        challengeStarted = true;
      }
      map.removeLayer(chkPlaceLayer);

      // open text and picture

      if (currentChallengeData.path.length - 1 > currentCheckpoint) {

        callBack('chk', currentChallengeData.path[currentCheckpoint], currentCheckpoint);
      }

      // next checkpoint
      currentCheckpoint++;
      setupCheckpoint();
    }

    this.startGame = function () {
      setTimeout(function () {
        map.updateSize();
        runGame();
      }, 10);
    }

    this.pauseGame = function () {

      if (gameIsRunning) {
        //console.log('pause game');
        pauseGame();
      } else {
        //console.log('run game');
        runGame();
      }
    }
    this.stopGame = function () {
      pauseGame();
    }

    this.playGame = function () {
      runGame();
    }

    this.advCheckpoint = function () {
      checkpointReached();
    }


    // start to create game elements
    setUpGameZone(gameZoneEl);

  }
}

//-------------------------------------------------------------------------------------------------------------------------
// helper scripts
//-------------------------------------------------------------------------------------------------------------------------

// convert radians to degrees
function radToDeg(rad) {
  return rad * 180 / Math.PI;
}
// convert degrees to radians
function degToRad(deg) {
  return deg * Math.PI / 180;
}
// modulo for negative values
function mod(n) {
  return ((n % (2 * Math.PI)) + (2 * Math.PI)) % (2 * Math.PI);
}

function getDistanceAlt(coordinate1, coordinate2, unit) {

  var lat1 = coordinate1[1];
  var lat2 = coordinate2[1];
  var lon1 = coordinate1[0];
  var lon2 = coordinate2[0];

  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  } else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "M") {
      dist = dist;
    } else if (unit == "N") {
      dist = dist * 0.8684;
    } else {
      dist = dist * 1.609344;
    }
    return dist;
  }
}

function solveDegrees(deg) {
  if (deg > 360) {
    deg -= 360;
  } else if (deg < 0) {
    deg += 360;
  }
  return (deg);
}

function msToTime(duration) {
  var milliseconds = parseInt((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
  //return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
}


export {
  GameFunctions
};
import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;

// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// firebase
const fb = require('../js/fb.js');


let app;

fb.auth.onAuthStateChanged(user => {
  if (!app) {
    // Init App
    app = new Vue({
      el: '#app',
      render: (h) => h(App),      

      navbar: {
        iosCenterTitle: true,
        mdCenterTitle: true,
        auroraCenterTitle: true,
      },
      touch: {
        tapHold: true,
        tapHoldPreventClicks: true
      },

      // Register App Component
      components: {
        app: App
      },
      
    })
  }
});

var currentHistorLength;



window.addEventListener("load", function() {
  window.history.pushState({}, "");
  currentHistorLength = window.history.length;
});

/*
window.addEventListener("popstate", function() {
  window.history.pushState({}, "");
  console.log('back');
  console.log(app.url);
});
*/


$(document).ready(function () {
  console.log('jQuery');

});



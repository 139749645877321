<template>
  <f7-app :params="f7params" theme-dark>
    <!-- Your main view, should have "view-main" class -->
    <f7-view main class="safe-areas" url="/"></f7-view>
  </f7-app>
</template>
<script>
//AjvWxy9/I4V0jo+cnYq32y0XCasJEqbVr7vNk7hGj3W7N+qbtmEbKUL3Gqc2LsFFJkY8nvUHwoHfhyZtlQr2rwkAAABoeyJvcmlnaW4iOiJodHRwczovL3ByaW11cy5zdGFubGlnaHQuY2g6NDQzIiwiZmVhdHVyZSI6Ildha2VMb2NrIiwiZXhwaXJ5IjoxNTg3NTEzNTk5LCJpc1N1YmRvbWFpbiI6dHJ1ZX0=
window.startHistoryLength = window.history.length;
import routes from "../js/routes.js";

export default {
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        name: "Primus Challenge", // App name
        theme: "auto",

        dialog:{
          buttonOk: 'OK',
          buttonCancel: 'Cancel',
        },

        navbar: {
          iosCenterTitle: true,
          mdCenterTitle: true,
          auroraCenterTitle: true
        }, // Automatic theme detection

        animate: true,

        // App routes
        routes: routes,

        // Register service worker
        serviceWorker: {
          path: "/service-worker.js"
        }
      }
    };
  },
  methods: {
    //
  },
  mounted() {
    this.$f7ready(f7 => {
      // Call F7 APIs here
    });
  }
};
</script>